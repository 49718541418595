import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageType } from '../types'
import { ArticleContent, Image } from '../components'
import GiteMenu from './Gites/GiteMenu'
import ExperienceMenu from './Experiences/ExperienceMenu'
import { ArrowDownCircleIcon } from "@heroicons/react/24/solid"

const FaqView = () => {
    const { t } = useTranslation(['faq']);

    //Choisir une image pour l'en-tête: mane, folder, title (format idéal: 900 x 420 )
    const faqHeader: ImageType = {
        name: 'chenilliere-facade.png',
        folder: 'chenilliere',
        title: 'La chenillière'
    }

    return (
        <div>
            <section className='w-full h-[300px] sm:h-[340px] lg:h-[420px] relative'>
                <div className='w-full h-full absolute flex flex-col items-center justify-center bg-transparent text-white z-10'>
                    <div className='font-NotoSans text-center text-lodgeRed text-4xl font-bold mb-1'>{t('title')}</div>
                    <div className='font-NotoSans text-center text-3xl mb-1'>{t('subTitle')}</div>
                </div>

                <div className='w-full h-full z-0'>
                    <Image
                        name={faqHeader.name}
                        folder={faqHeader.folder}
                        title={faqHeader.title}
                        className="w-full h-full object-cover z-0"
                    />
                </div>
            </section>

            <section>
                <div className='m-4'>
                    {(t('content', {returnObjects: true}) as any[]).map((qr, k) =>
                        <div key={k} className='text-lodgeGrey mx-6 my-4'>
                            <div className='font-bold mt-2 mb-1'>{qr.q}</div>
                            <div className='bg-lodgeGreyLight rounded-lg p-2'>
                                <ArticleContent contents={qr.r} />
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <GiteMenu />

            <ExperienceMenu />
        </div>
    )
}

const FaqCard = () => {
    const { t } = useTranslation(['faq']);
    const faq = useRef<HTMLDivElement>(null);
    const [openFaq, setOpenFaq] = useState<boolean>(false)
    const [openResponse, setOpenResponse] = useState<number | undefined>()

    const scrollTop = () => {
        if (!openFaq && faq.current && faq.current?.getBoundingClientRect().y > 300) {
            faq.current.scrollIntoView({behavior: 'smooth'})
        }
    }

    return (
        <div ref={faq} className="w-full bg-lodgeGreyLight text-lodgeGrey scroll-mt-28 pb-4 mb-6">
            <button
                className={[
                    'w-full px-2 sm:px-4',
                    openFaq ? 'pb-2' : ''
                ].join(' ')}
                onClick={() => {
                    scrollTop()
                    setOpenFaq(!openFaq)
                }}
            >
                <div className='w-full flex justify-center items-center text-center pt-4'>
                    <span className='uppercase mr-4'>{t('title')}</span>
                    <ArrowDownCircleIcon className={[
                        'flex-none w-6 h-6',
                        openFaq ? 'rotate-180' : ''
                    ].join(' ')} />
                </div>
            </button>

            {openFaq &&
            <div className='bg-white px-2 py-4'>
                {(t('content', {returnObjects: true}) as {q: string, r: []}[]).map((c, k) =>
                    <button
                        key={k}
                        className='w-full flex flex-col px-2'
                        onClick={() => openResponse === k ? setOpenResponse(undefined) : setOpenResponse(k)}
                    >
                        <div className='flex flex-row items-center mt-2 mb-1 px-2'>
                            <div className='font-medium text-justify  mr-2'>{c.q}</div>
                            <ArrowDownCircleIcon className={[
                                'flex-none w-6 h-6 text-lodgeGrey',
                                openResponse === k ? 'rotate-180' : ''
                            ].join(' ')} />
                        </div>
                        {openResponse === k && 
                        <div className='bg-lodgeGreyLight text-justify rounded-lg p-2'>
                            <ArticleContent contents={c.r} />
                        </div>
                        }
                    </button>
                )}
            </div>
            }
        </div>
    )
}

export {FaqView, FaqCard}