import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { useClickOutside } from "../utils/useClickOutside"
import { useTranslation } from "react-i18next"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { BaseContainerLabel } from "./BaseContainerLabel"
import { CountryCode, Country, Phone } from "../types"
import { BaseSelect } from "./BaseSelect"
import { ShowFlag } from "./ShowFlag"
import { useGetCountries } from "../utils/useGetCountries"

import { AsYouType, isValidPhoneNumber, findPhoneNumbersInText }  from  'libphonenumber-js'

type InputTelProps = {
    id?: string
    name: string
    label: string
    phone: Phone
    setPhone: (p: Phone) => void
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    containerClass?: string
    selectClass?: string
    isRequire?: boolean
    autofocus?: boolean
}

const InputTel = ({id, name, label, phone, setPhone, onChange, containerClass, selectClass, isRequire = false }: InputTelProps) => {
    const { t } = useTranslation(['countries', 'nav']);
    const containerRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const inputSelectRef = useRef<HTMLInputElement>(null)
    const countries = useGetCountries()
    const [country, setCountry] = useState<{code: CountryCode | undefined, title: string}>({code: phone.countryCode, title: ''})
    const [search, setSetSearch] = useState<string>()
    const [searchList, setSearchList] = useState<Country[]>(countries)
    const [isValid, setIsValid] = useState<boolean>(!isRequire)

    const [openSelect, setOpenSelect] = useState<boolean>(false)

    const closeOpenLang = useCallback(() => setOpenSelect(false), []);
    useClickOutside(containerRef, closeOpenLang);

    useEffect(() => {
        if (openSelect && inputSelectRef.current) inputSelectRef.current.focus()
    }, [openSelect])


    useEffect(() => {
        if (search) {
            const searchCode = countries.filter(cs => cs.code.includes(search.toUpperCase()))
            const searchTitle = countries.filter(cs => cs.title.toLowerCase().includes(search.toLowerCase()))
            if (searchTitle.length) {
                for (let i = 0; i < searchTitle.length; i++) {
                    if (!searchCode.find(sl => sl.code === searchTitle[i].code)) searchCode.push(searchTitle[i])
                }
            }

            return setSearchList(searchCode)
        }

        setSearchList(countries)
        inputSelectRef.current?.focus()
    }, [search, countries])

    const addCountry = (title: string) => {
        const isCountry = countries.find(c => c.title.toLowerCase() === title.toLowerCase())
        if (isCountry) {
            setCountry(isCountry)
        } else {
            setCountry({code: undefined, title: title})
        }
    }

    const checkValid = () => {
        if (isRequire) {
            if (
                (country.code && isValidPhoneNumber(phone.num, country.code)) ||
                (!country.code && phone.num.length > 2)
            ) return setIsValid(true)
        } else {
            if (
                (phone.num === '') ||
                (country.code && isValidPhoneNumber(phone.num, country.code)) ||
                (!country.code && phone.num.length > 2)
            ) return setIsValid(true)
        }
        
        return setIsValid(false)
    }


    const numberFormat = (number: string) => {
        if (country.code) {
            setPhone({num: new AsYouType(country.code).input(number), countryCode: country.code})
        } else {
            const n = findPhoneNumbersInText(number)
            if (n.length > 0 && n[0].number.country) setCountry({code: n[0].number.country, title: ''})
            setPhone({num: number})
        }
    }

    return (
        <BaseContainerLabel
            id={id}
            name={name}
            label={label}
            containerRef={containerRef}
            containerClass={[
                containerClass,
                "w-full h-9 relative flex flex-row justify-between rounded-lg border-lodgeGrey border-x border-b mt-3",
                isValid ? "bg-white bg-opacity-10 " : "bg-danger bg-opacity-20",
                "focus-within:bg-white bg-opacity-10 focus-within:bg-opacity-60",
            ].join(' ')}
            labelClass='scale-75 px-3 -mt-2 mb-2'
        >
            <div className="w-full flex justify-between items-center mx-1 mt-1">
                <button
                    type="button"
                    className="flex items-center"
                    onClick={() => setOpenSelect(!openSelect)}
                >
                    <ShowFlag country={country} className="h-4 w-6 mr-1" classColorNoFlag="bg-blue-100" />
                    <ChevronDownIcon className="h-3 w-3"/>   
                </button>

                <input
                    type='tel'
                    id={name}
                    ref={inputRef}
                    value={phone.num}
                    onChange={(event) => numberFormat(event.target.value)}
                    onBlur={() => checkValid()}
                    required={isRequire}
                    className="grow outline-none shadow-none bg-transparent text-lg mt-1 ml-3"
                />
            </div>

            <BaseSelect 
                open={openSelect}
                parentRef={containerRef}
                selectClass="w-full flex flex-col bg-white border border-lodgeGrey rounded-lg mx-1"
            >  
                <div className="w-full flex flex-col bg-white border-b border-inherit rounded-t-lg px-2">                            
                    <input
                        ref={inputSelectRef}
                        type='text'
                        value={search ? search : ''}
                        onChange={(event) => setSetSearch(event.target.value)}
                        className="grow outline-none shadow-none bg-transparent text-lg mt-1"
                    />

                    <button
                        type="button"
                        className="flex items-center border-t border-inherit py-2"
                        onClick={() => {
                            addCountry('')
                            setOpenSelect(false)
                            inputRef.current?.focus()
                        }}
                    >
                        <ShowFlag className="h-4 w-6 mr-2 mb-1" classColorNoFlag="bg-blue-100"/>
                        <span>{t('book.unknown', {ns: 'nav'})}</span>
                    </button>
                </div>

                <div className="left-0 overflow-y-auto no-scrollbar">
                    {searchList.map((c, k) =>
                    <button key={k}
                        type="button"
                        className={[selectClass, 'w-full flex items-center hover:bg-lodgeGreyLight p-2'].join(' ')}
                        onClick={() => {
                            addCountry(c.title)
                            setOpenSelect(false)
                            inputRef.current?.focus()
                        }}
                    >
                        <ShowFlag country={c} className="h-4 w-6 mr-2 mb-1" classColorNoFlag="bg-blue-100" /> 
                        <div className="truncate">{c.title}</div>
                    </button>
                    )}
                </div>
           
            </BaseSelect>
        
        </BaseContainerLabel>

    );
}
    


export { InputTel }