import { ValueLabel } from '../types'
import { CheckIcon } from "@heroicons/react/24/outline"

type InputCheckboxProps = {
  id: string
  items: ValueLabel[]
  checkedId: string[]
  setCheckedId: (c: string[]) => void
  showIf?: boolean
  col?: boolean
  containerClass?: string
  labelClass?: string
  chechboxClass?: string
}

const InputCheckbox = ({ id, items, checkedId, setCheckedId, containerClass, labelClass, chechboxClass, col= true, showIf = true}: InputCheckboxProps) => {

  const checkedToogle = (id: string) => {
    const newChecked = [...checkedId]
    const index = newChecked.findIndex(c => c === id)
    index > -1 ? newChecked.splice(index, 1) : newChecked.push(id)
    setCheckedId(newChecked)
  }

  return (
    <div
      className={[
        'w-full flex cursor-pointer mt-2 px-1"',
        col ? 'flex-col' : 'flex-wrap',
        containerClass,
        showIf ? '' : 'hidden'
      ].join(' ') }
    >
      <input
        id={id}
        name={id} 
        type="hidden"
        value={checkedId}
      />

      {items.map(i =>
      <button
        key={i.id}
        type="button"
        className={[
          'flex flex-col mb-2',
          labelClass
        ].join(' ')}
        onClick={() => checkedToogle(i.id)}
      >
        <div className="flex items-center h-6 text-white mr-1.5">
          {checkedId.find(c => c === i.id) !== undefined
          ? <div className={[
              "h-5 w-5 rounded",
              'bg-success border border-success'
              ].join(' ')}>
              <CheckIcon strokeWidth={4} stroke="white"  />
            </div>
          : <div className="h-5 w-5 border-2 border-lodgeGrey rounded-md" />
          }

          <div className="h-fit text-lodgeGrey mt-0.5 ml-2">{i.label}</div>
        </div>
        
      </button>
      )}
    </div>
  )
}

export { InputCheckbox }