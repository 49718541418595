import { useRef } from "react"
import { BaseContainerLabel } from "./BaseContainerLabel"

type CardLabelProps = {
    children: React.ReactNode | string;
    label: string
    containerClass?: string
}

const CardLabel = ({children, label, containerClass }: CardLabelProps) => {
    const containerRef = useRef<HTMLDivElement>(null)

    return (

        <BaseContainerLabel
            label={label}
            name=''
            containerRef={containerRef}
            containerClass={[
                containerClass,
                "w-full h-9 relative flex flex-row justify-between rounded-lg border-lodgeGrey border-x border-b mt-3",
                "bg-white bg-opacity-10",
            ].join(' ')}
            labelClass='scale-75 px-3 -mt-2 mb-2'
        >
            {children}
        </BaseContainerLabel>
    )
}

export { CardLabel }

/*

        <div
            ref={divRef}
            className={["h-fit relative rounded-lg bg-opacity-95",
                containerClass,
                borderColor ? borderColor : 'border-white',
                "border-b border-x rounded-l-lg before:absolute before:top-0 before:h-2 before:border-inherit before:left-0 before:w-2 before:border-t before:rounded-tl-lg"
            ].join(' ')}
        >
            <div
                className={[
                    "absolute top-0 right-0 h-2 border-inherit border-t rounded-tr-lg",
                ].join(' ')}
                style={{width: borderTopWidth}}
            />
            
            <div
                ref={labelRef}
                className='absolute top-0 left-0 origin-top-left scale-75 px-3 -mt-2 mb-2'
            >
                {label}
            </div>

            <div style={{marginTop: marginTop}}>{children}</div>
        
        </div>
*/