export const BabyBed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 125" enableBackground="new 0 0 100 100;" fill="#fff" className="w-14 h-12 stroke-white">
            <path d="M40.316,22.342c-0.741-1.482-0.141-3.284,1.342-4.025C45.092,16.6,48.544,15.568,52,15.183V9c0-1.657,1.343-3,3-3h21  c8.271,0,15,6.729,15,15c0,1.657-1.343,3-3,3s-3-1.343-3-3c0-4.963-4.037-9-9-9H58v3.183c3.456,0.385,6.908,1.417,10.342,3.134  c1.482,0.741,2.083,2.543,1.342,4.025C69.158,23.394,68.099,24,66.997,24c-0.45,0-0.908-0.101-1.339-0.316  c-3.578-1.789-7.107-2.684-10.636-2.688C55.014,20.996,55.008,21,55,21s-0.014-0.004-0.022-0.004  c-3.529,0.004-7.058,0.899-10.636,2.688C42.856,24.425,41.058,23.823,40.316,22.342z"/>
            <path d="M40,30c0-1.657,1.343-3,3-3s3,1.343,3,3c0,1.657-1.343,3-3,3S40,31.657,40,30z"/>
            <path d="M55,27c1.657,0,3,1.343,3,3c0,1.657-1.343,3-3,3s-3-1.343-3-3C52,28.343,53.343,27,55,27z"/>
            <path d="M70,30c0,1.657-1.343,3-3,3s-3-1.343-3-3c0-1.657,1.343-3,3-3S70,28.343,70,30z"/>
            <path d="M91,27h-6c-1.657,0-3,1.343-3,3s1.343,3,3,3v6H16v-6c1.657,0,3-1.343,3-3s-1.343-3-3-3h-6c-1.657,0-3,1.343-3,3s1.343,3,3,3  v57c0,1.658,1.343,3,3,3s3-1.342,3-3v-3h69v3c0,1.658,1.343,3,3,3s3-1.342,3-3V33c1.657,0,3-1.343,3-3S92.657,27,91,27z M85,69h-9  V45h9V69z M61,69V45h9v24H61z M46,69V45h9v24H46z M31,69V45h9v24H31z M25,45v24h-9V45H25z M16,81v-6h69v6H16z"/>
        </svg>
    )
}