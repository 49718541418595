export const SwimmingPool = () => {
    return (
		<svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
	 	viewBox="0 0 460 460" className="w-10 h-10" >
			<g>
			<circle fill="#fff"  cx="320.055" cy="189.491" r="40.898"/>
				<path fill="#fff" d="M170.109,293.715c3.413,0,6.613-1.32,9.01-3.717c13.635-13.635,31.957-21.281,51.438-21.281
					c19.868,0,38.033,7.876,51.439,21.282c2.441,2.441,5.556,3.732,9.009,3.732c6.199,0,8.572-3.522,12.456-6.941
					c0,0,3.06-41.835-36.625-80.26l-7.894,22.968l6.244-82.155l65.375-36.625c11.042-6.186,14.605-20.653,7.144-31.325
					c-6.316-9.042-18.549-11.951-28.32-6.474l-77.004,43.178c-6.33,3.546-10.463,10.024-11.014,17.258l-5.581,89.56l-3.546-20.456
					l-46.552,33.521l0,0l-46.364,33.386c15.778,2.077,30.352,9.21,41.774,20.632C163.495,292.395,166.695,293.715,170.109,293.715z"/>
				<path fill="#fff" d="M434.472,0H25.528C11.43,0,0,11.429,0,25.528c0,15.24,0,393.166,0,408.943C0,448.57,11.43,460,25.528,460h408.943
					C448.57,460,460,448.57,460,434.471v-34.932v-48.427v-15.573v-48.427V25.528C460,11.429,448.57,0,434.472,0z M420,420H40
					c0-6.977,0-11.378,0-18.045c17.683,2.835,36.439-2.534,50.042-16.137c5.24-5.24,12.207-8.126,19.618-8.126
					c7.411,0,14.377,2.886,19.618,8.126c22.513,22.513,59.145,22.514,81.662,0c5.24-5.24,12.206-8.126,19.617-8.126
					c7.411,0,14.378,2.886,19.618,8.126c10.906,10.906,25.407,16.913,40.831,16.913c15.424,0,29.924-6.007,40.831-16.913
					c5.24-5.24,12.207-8.126,19.618-8.126c7.411,0,14.378,2.886,19.617,8.126c10.906,10.906,25.407,16.913,40.831,16.913
					c2.735,0,5.438-0.198,8.1-0.57C420,408.965,420,413.152,420,420z M420,371.536c-2.592,0.787-5.315,1.195-8.1,1.195
					c-7.41,0-14.377-2.886-19.617-8.126c-22.514-22.514-59.147-22.514-81.661,0c-5.24,5.24-12.207,8.126-19.618,8.126
					c-7.41,0-14.377-2.886-19.617-8.126c-10.907-10.906-25.407-16.913-40.831-16.913c-15.424,0-29.924,6.007-40.83,16.913
					c-10.817,10.816-28.419,10.816-39.235,0c-22.514-22.514-59.147-22.514-81.661,0c-5.24,5.24-12.207,8.126-19.618,8.126
					c-3.187,0-6.291-0.537-9.211-1.562c0-8.853,0-29.087,0-33.214c17.683,2.835,36.439-2.534,50.042-16.137
					c5.24-5.24,12.207-8.126,19.618-8.126c7.411,0,14.377,2.886,19.618,8.126c22.513,22.513,59.145,22.514,81.662,0
					c5.24-5.24,12.206-8.126,19.617-8.126c7.411,0,14.378,2.886,19.618,8.126c10.906,10.906,25.407,16.913,40.831,16.913
					c15.424,0,29.924-6.007,40.831-16.913c5.24-5.24,12.207-8.126,19.618-8.126c7.411,0,14.378,2.886,19.617,8.126
					c10.906,10.906,25.407,16.913,40.831,16.913c2.735,0,5.438-0.198,8.1-0.57C420,351.023,420,359.746,420,371.536z M420,307.536
					c-2.592,0.787-5.315,1.195-8.1,1.195c-7.41,0-14.377-2.886-19.617-8.126c-22.514-22.514-59.147-22.514-81.661,0
					c-5.24,5.24-12.207,8.126-19.618,8.126c-7.41,0-14.377-2.886-19.617-8.126c-10.907-10.906-25.407-16.913-40.831-16.913
					c-15.424,0-29.924,6.007-40.83,16.913c-10.817,10.816-28.419,10.816-39.235,0c-22.514-22.514-59.147-22.514-81.661,0
					c-5.24,5.24-12.207,8.126-19.618,8.126c-3.187,0-6.291-0.537-9.211-1.562C40,299.042,40,52.05,40,40h380
					C420,59.897,420,296.469,420,307.536z"/>
			</g>
		</svg>
    )
}