//import { useCallback, useEffect, useRef, useState } from "react"
import { SysCodeLang } from "../types"
//import { useDimElement } from "../utils/useDimElement"
//import { useDimWindow } from "../utils/useDimWindow"
//import { useClickOutside } from "../utils/useClickOutside"

type SelectLangProps = {
    value: SysCodeLang
    setValue: (t: SysCodeLang) => void
    containerClass?: string
    selectClass?: string
}

const SelectLang = ({value, setValue, containerClass, selectClass}: SelectLangProps) => {
    /*
    const windowDim = useDimWindow()
    const divRef = useRef<HTMLDivElement>(null)
    const selectRef = useRef<HTMLDivElement>(null)
    const selectDim = useDimElement<HTMLDivElement>(selectRef)

    const [openSelect, setOpenSelect] = useState<boolean>(false)
    const [selectBottom, setSelectBottom] = useState<boolean>(true)

    useEffect(() => {
        if (openSelect && divRef.current && windowDim) {
            const buttonPositionBottom = Math.ceil(divRef.current.getBoundingClientRect().y)
            const selectHeight = Math.ceil(selectDim.height)
            windowDim.height - (buttonPositionBottom + selectHeight + 30) > 0 ? setSelectBottom(false) : setSelectBottom(true)
        }
    }, [openSelect, divRef, selectDim, windowDim])

    const closeOpenLang = useCallback(() => setOpenSelect(false), [])
    useClickOutside(divRef, closeOpenLang)
    */

    return (
        <div className='relative'>
            <button
                onClick={() => {
                    setValue(value === 'en' ? 'fr' : 'en')
                }}
            >
                {value === 'en' ? 'FR' : 'EN'}
            </button>

        </div>
    )
}

export { SelectLang }

/*
        <div ref={divRef} className='relative'>
            <button
                onClick={() => {setOpenSelect(!openSelect)}}
            >
                {value.toUpperCase()}
            </button>

            <div
                ref={selectRef}
                className={[
                    openSelect ? '' : 'hidden',
                    containerClass,
                    'absolute right-0 max-h-[300px] flex flex-col bg-lodgeGrey bg-opacity-80 border border-white rounded-lg text-white px-4 pt-4 z-50',
                    selectBottom ? 'bottom-8' : 'top-8'
                ].join(' ')}
            >
                {sysLangs.map((l, k) =>
                    <button key={k}
                        className={[selectClass, 'pb-4'].join(' ')}
                        onClick={() => {
                            setValue(l.code)
                            setOpenSelect(false)
                        }}
                    >
                        {l.title}
                    </button>
                )}
            </div>

        </div>
    */
