var flags = require('./')

exports.AC = flags.AC;
exports.AD = flags.AD;
exports.AE = flags.AE;
exports.AF = flags.AF;
exports.AG = flags.AG;
exports.AI = flags.AI;
exports.AL = flags.AL;
exports.AM = flags.AM;
exports.AO = flags.AO;
exports.AQ = flags.AQ;
exports.AR = flags.AR;
exports.AS = flags.AS;
exports.AT = flags.AT;
exports.AU = flags.AU;
exports.AW = flags.AW;
exports.AX = flags.AX;
exports.AZ = flags.AZ;
exports.BA = flags.BA;
exports.BB = flags.BB;
exports.BD = flags.BD;
exports.BE = flags.BE;
exports.BF = flags.BF;
exports.BG = flags.BG;
exports.BH = flags.BH;
exports.BI = flags.BI;
exports.BJ = flags.BJ;
exports.BL = flags.BL;
exports.BM = flags.BM;
exports.BN = flags.BN;
exports.BO = flags.BO;
exports.BQ = flags.BQ;
exports.BR = flags.BR;
exports.BS = flags.BS;
exports.BT = flags.BT;
exports.BV = flags.BV;
exports.BW = flags.BW;
exports.BY = flags.BY;
exports.BZ = flags.BZ;
exports.CA = flags.CA;
exports.CC = flags.CC;
exports.CD = flags.CD;
exports.CF = flags.CF;
exports.CG = flags.CG;
exports.CH = flags.CH;
exports.CI = flags.CI;
exports.CK = flags.CK;
exports.CL = flags.CL;
exports.CM = flags.CM;
exports.CN = flags.CN;
exports.CO = flags.CO;
exports.CR = flags.CR;
exports.CU = flags.CU;
exports.CV = flags.CV;
exports.CW = flags.CW;
exports.CX = flags.CX;
exports.CY = flags.CY;
exports.CZ = flags.CZ;
exports.DE = flags.DE;
exports.DJ = flags.DJ;
exports.DK = flags.DK;
exports.DM = flags.DM;
exports.DO = flags.DO;
exports.DZ = flags.DZ;
exports.EC = flags.EC;
exports.EE = flags.EE;
exports.EG = flags.EG;
exports.EH = flags.EH;
exports.ER = flags.ER;
exports.ES = flags.ES;
exports.ET = flags.ET;
exports.EU = flags.EU;
exports.FI = flags.FI;
exports.FJ = flags.FJ;
exports.FK = flags.FK;
exports.FM = flags.FM;
exports.FO = flags.FO;
exports.FR = flags.FR;
exports.GA = flags.GA;
exports.GB = flags.GB;
exports.GD = flags.GD;
exports.GE = flags.GE;
exports.GF = flags.GF;
exports.GG = flags.GG;
exports.GH = flags.GH;
exports.GI = flags.GI;
exports.GL = flags.GL;
exports.GM = flags.GM;
exports.GN = flags.GN;
exports.GP = flags.GP;
exports.GQ = flags.GQ;
exports.GR = flags.GR;
exports.GS = flags.GS;
exports.GT = flags.GT;
exports.GU = flags.GU;
exports.GW = flags.GW;
exports.GY = flags.GY;
exports.HK = flags.HK;
exports.HM = flags.HM;
exports.HN = flags.HN;
exports.HR = flags.HR;
exports.HT = flags.HT;
exports.HU = flags.HU;
exports.IC = flags.IC;
exports.ID = flags.ID;
exports.IE = flags.IE;
exports.IL = flags.IL;
exports.IM = flags.IM;
exports.IN = flags.IN;
exports.IO = flags.IO;
exports.IQ = flags.IQ;
exports.IR = flags.IR;
exports.IS = flags.IS;
exports.IT = flags.IT;
exports.JE = flags.JE;
exports.JM = flags.JM;
exports.JO = flags.JO;
exports.JP = flags.JP;
exports.KE = flags.KE;
exports.KG = flags.KG;
exports.KH = flags.KH;
exports.KI = flags.KI;
exports.KM = flags.KM;
exports.KN = flags.KN;
exports.KP = flags.KP;
exports.KR = flags.KR;
exports.KW = flags.KW;
exports.KY = flags.KY;
exports.KZ = flags.KZ;
exports.LA = flags.LA;
exports.LB = flags.LB;
exports.LC = flags.LC;
exports.LI = flags.LI;
exports.LK = flags.LK;
exports.LR = flags.LR;
exports.LS = flags.LS;
exports.LT = flags.LT;
exports.LU = flags.LU;
exports.LV = flags.LV;
exports.LY = flags.LY;
exports.MA = flags.MA;
exports.MC = flags.MC;
exports.MD = flags.MD;
exports.ME = flags.ME;
exports.MF = flags.MF;
exports.MG = flags.MG;
exports.MH = flags.MH;
exports.MK = flags.MK;
exports.ML = flags.ML;
exports.MM = flags.MM;
exports.MN = flags.MN;
exports.MO = flags.MO;
exports.MP = flags.MP;
exports.MQ = flags.MQ;
exports.MR = flags.MR;
exports.MS = flags.MS;
exports.MT = flags.MT;
exports.MU = flags.MU;
exports.MV = flags.MV;
exports.MW = flags.MW;
exports.MX = flags.MX;
exports.MY = flags.MY;
exports.MZ = flags.MZ;
exports.NA = flags.NA;
exports.NC = flags.NC;
exports.NE = flags.NE;
exports.NF = flags.NF;
exports.NG = flags.NG;
exports.NI = flags.NI;
exports.NL = flags.NL;
exports.NO = flags.NO;
exports.NP = flags.NP;
exports.NR = flags.NR;
exports.NU = flags.NU;
exports.NZ = flags.NZ;
exports.OM = flags.OM;
exports.PA = flags.PA;
exports.PE = flags.PE;
exports.PF = flags.PF;
exports.PG = flags.PG;
exports.PH = flags.PH;
exports.PK = flags.PK;
exports.PL = flags.PL;
exports.PM = flags.PM;
exports.PN = flags.PN;
exports.PR = flags.PR;
exports.PS = flags.PS;
exports.PT = flags.PT;
exports.PW = flags.PW;
exports.PY = flags.PY;
exports.QA = flags.QA;
exports.RE = flags.RE;
exports.RO = flags.RO;
exports.RS = flags.RS;
exports.RU = flags.RU;
exports.RW = flags.RW;
exports.SA = flags.SA;
exports.SB = flags.SB;
exports.SC = flags.SC;
exports.SD = flags.SD;
exports.SE = flags.SE;
exports.SG = flags.SG;
exports.SH = flags.SH;
exports.SI = flags.SI;
exports.SJ = flags.SJ;
exports.SK = flags.SK;
exports.SL = flags.SL;
exports.SM = flags.SM;
exports.SN = flags.SN;
exports.SO = flags.SO;
exports.SR = flags.SR;
exports.SS = flags.SS;
exports.ST = flags.ST;
exports.SV = flags.SV;
exports.SX = flags.SX;
exports.SY = flags.SY;
exports.SZ = flags.SZ;
exports.TA = flags.TA;
exports.TC = flags.TC;
exports.TD = flags.TD;
exports.TF = flags.TF;
exports.TG = flags.TG;
exports.TH = flags.TH;
exports.TJ = flags.TJ;
exports.TK = flags.TK;
exports.TL = flags.TL;
exports.TM = flags.TM;
exports.TN = flags.TN;
exports.TO = flags.TO;
exports.TR = flags.TR;
exports.TT = flags.TT;
exports.TV = flags.TV;
exports.TW = flags.TW;
exports.TZ = flags.TZ;
exports.UA = flags.UA;
exports.UG = flags.UG;
exports.UM = flags.UM;
exports.US = flags.US;
exports.UY = flags.UY;
exports.UZ = flags.UZ;
exports.VA = flags.VA;
exports.VC = flags.VC;
exports.VE = flags.VE;
exports.VG = flags.VG;
exports.VI = flags.VI;
exports.VN = flags.VN;
exports.VU = flags.VU;
exports.WF = flags.WF;
exports.WS = flags.WS;
exports.XK = flags.XK;
exports.YE = flags.YE;
exports.YT = flags.YT;
exports.ZA = flags.ZA;
exports.ZM = flags.ZM;
exports.ZW = flags.ZW;

