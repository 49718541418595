import { isEven } from "../utils/isEven"
import { Image } from "./Image"
import { MapPinIcon, GlobeAltIcon, PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid"

import { ImageType, ArticleTextType, ContentType, paragraph, title, subTitle, poscriptum, puce, phone, email, link, address, imageContent} from '../types'

type ArticleProps = {
    order: number
    image: ImageType
    text?: ArticleTextType
}

const Article = ({order, image, text}: ArticleProps) => {

    return (
        <>
        {text &&
        <article
            className={[
                'w-full h-fit sm:min-h-[332px] lg:min-h-[468px] mb-8 sm:py-4 lg:py-6',
                isEven(order) ? '' : 'bg-lodgeGreyLight'
            ].join(' ')}
        >

            <Image
                name={image.name}
                folder={image.folder}
                title={text.imageTitle ? text.imageTitle : text.title}
                className={[
                    "w-full h-[300px] sm:w-[300px] sm:h-[300px] lg:w-1/2 lg:h-[420px] object-cover sm:mx-4 lg:mx-6",
                    isEven(order) ? 'float-left porder-1' : 'float-right porder-1 sm:porder-2'
                ].join(' ')}
            />

            <div className={[
                    "w-full h-full px-2 sm:px-4 md:px-6",
                    isEven(order) ? 'porder-2' : 'porder-2 sm:porder-1'
                ].join(' ')}
            >
                <div className="text-center mt-1 mb-2">
                    <div className="font-bold text-xl">{text.title}</div>
                    <div className="text-lg italic">{text.subTitle}</div>
                </div>

                <ArticleContent contents={text.content} />

            </div>
        </article>
        }
        </>
    );
}

type PresentationProps = {
    intro: ContentType[]
}

const Presentation = ({intro}: PresentationProps) => {

    return (
        <div className="w-full h-fit  bg-lodgeGreyLight text-lodgeGrey font-NotoSans px-6 sm:px-8 py-4 sm:py-6">

            {Array.isArray(intro) &&
            <ArticleContent contents={intro} />
            }

        </div>
    );
}


type DescriptionGiteProps = {
    qickDescription: string[]
    intro: ContentType[]
    text: ContentType[]
}

const DescriptionGite = ({qickDescription, intro, text}: DescriptionGiteProps) => {

    return (
        <>
            <div className="w-full h-fit  bg-lodgeGreyLight text-lodgeGrey font-NotoSans px-6 sm:px-8 py-4 sm:py-6">
                <div className="flex flex-wrap justify-center divide-x divide-lodgeGrey text-xl mx-2 mb-2">
                    {Array.isArray(qickDescription) && qickDescription.map((qd, k) =>
                        <h3
                            key={k}
                            className="text-center px-2 text-lodgeRed"
                        >
                            {qd}
                        </h3>
                    )}
                </div>

                {Array.isArray(intro) &&
                <ArticleContent contents={intro} />
                }

            </div>

            <article
                className={[
                    'w-full h-fit sm:min-h-[332px] lg:min-h-[468px] px-4 lg:px-8 mb-8 sm:py-4 lg:py-6',
                ].join(' ')}
            >
                {Array.isArray(text) &&
                <ArticleContent contents={text} />
                }
            </article>
        </>
    );
}


type ArticleContentProps = {
    contents: ContentType[]
}

const ArticleContent = ({contents}: ArticleContentProps) => {

    const content = (c: ContentType, k: number) => {
        const hidden = <span key={k} className="hidden"/>

        switch(Object.keys(c)[0]) {
            case 'p':
                return <div key={k} className="text-justify whitespace-pre-line mt-2">{(c as paragraph).p}</div>

            case 'title':
                return <div key={k} className="font-semibold text-center text-lg mt-4 mb-2">{(c as title).title}</div>

            case 'subTitle':
                return <div key={k} className="text-center text-lg mb-1">{(c as subTitle).subTitle}</div>

            case 'ps':
                return <div key={k} className="text-center italic text-base mb-1">{(c as poscriptum).ps}</div>

            case 'puce':
                return (
                <div key={k} className="flex text-justify mt-1">
                    <div className="text-[8px] pt-1.5 mr-4">{'\u2B24'}</div>
                    <div className="text-justify whitespace-pre-line">{(c as puce).puce}</div>
                </div>)

            case 'phone':
                return (c as phone).phone ? (
                    <a
                        key={k}
                        href={"tel:" + (c as phone).phone}
                        className="flex flex-row whitespace-pre-line mt-1"
                    >
                        <PhoneIcon className="h-5 w-5 mr-2" />
                        {(c as phone).phone}
                    </a>) : hidden

            case 'email':
                return (c as email).email ? (
                    <a
                        key={k}
                        href={"maito:" + (c as email).email}
                        target="blank"
                        className="flex flex-row whitespace-pre-line mt-1"
                    >
                        <EnvelopeIcon className="h-5 w-5 mr-2" />
                        {(c as email).email}
                    </a>) : hidden

            case 'link':
                return (c as link).link ? (
                    <a
                        key={k}
                        href={(c as link).link}
                        target="blank"
                        className="flex flex-row whitespace-pre-line mt-1"
                    >
                        <GlobeAltIcon className="h-5 w-5 mr-2" />
                        {(c as link).link}
                    </a>
                ) : hidden

            case 'address':
                if ((c as address).address) {
                    const child = <><MapPinIcon className="h-5 w-5 mr-2" />{(c as address).address}</>
                    const coords = (c as address).coords

                    if (coords) {
                        return (
                        <a
                            key={k}
                            href={"https://maps.google.com?q=" + coords}
                            target={"blank"}
                            className="flex flex-row whitespace-pre-line mt-1"
                        >
                            {child}
                        </a>)
                    } else {
                        return (
                            <div key={k} className="flex flex-row whitespace-pre-line mt-1">{child}</div>
                        )
                    }
                }

                return hidden

            case 'image':
                return (c as imageContent).image.image ? (
                    <Image
                        key={k}
                        name={(c as imageContent).image.image.name}
                        folder={(c as imageContent).image.image.folder}
                        title={(c as imageContent).image.image.title}
                        className={[
                            "object-cover object-center",
                            imageClass((c as imageContent).image.align)
                        ].join(' ')}
                    />) : hidden

            default:
                return hidden
        }
    }

    const imageClass = (align = 'center') => {
        switch(align) {
            case 'left':
                return 'w-full lg:w-1/3 min-w-[200px] max-h-[320px] lg:max-h-[400px] lg:float-left lg:mr-8 sm:mr-4 mt-2 mb-2 sm:mb-4'
            case 'right':
                return 'w-full lg:w-1/3 min-w-[200px] max-h-[320px] lg:max-h-[400px] lg:float-right lg:ml-8 sm:ml-4 mt-2 mb-2 sm:mb-4'
            default:
                return 'h-[200px] mx-auto my-2'
        }
    }

    return (
        <>
            {contents.map((c, k) => (
                content(c, k)
            ))}
        </>
    )
}

export { Article, ArticleContent, DescriptionGite, Presentation }