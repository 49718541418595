import { useEffect, useState } from "react";
import { enUS } from 'date-fns/locale';
import { useNavGetLocale } from "./useNavGetLocale";

export const useNavLocaleDate = () => {
    const [dateLang, setDateLang] = useState<Locale>(enUS);
    const navLocale = useNavGetLocale()

    useEffect(() => {
        let dl: string | undefined

        dl = sysDateLangs.find(l => l === navLocale.langCode + navLocale.countryCode)
        if (!dl) dl = sysDateLangs.find(l => l === navLocale.langCode)
        if (!dl) dl = 'enUS'

        setDateLang(require("date-fns/locale")[dl])

    }, [navLocale]);
    
    return dateLang;
}

const sysDateLangs =[
    'af',
    'ar',
    'arDZ',
    'arEG',
    'arMA',
    'arSA',
    'arTN',
    'az',
    'be',
    'beTarask',
    'bg',
    'bn',
    'bs',
    'ca',
    'cs',
    'cy',
    'da',
    'de',
    'deAT',
    'el',
    'enAU',
    'enCA',
    'enGB',
    'enIE',
    'enIN',
    'enNZ',
    'enUS',
    'enZA',
    'eo',
    'es',
    'et',
    'eu',
    'faIR',
    'fi',
    'fr',
    'frCA',
    'frCH',
    'fy',
    'gd',
    'gl',
    'gu',
    'he',
    'hi',
    'hr',
    'ht',
    'hu',
    'hy',
    'id',
    'is',
    'it',
    'itCH',
    'ja',
    'jaHira',
    'ka',
    'kk',
    'km',
    'kn',
    'ko',
    'lb',
    'lt',
    'lv',
    'mk',
    'mn',
    'ms',
    'mt',
    'nb',
    'nl',
    'nlBE',
    'nn',
    'oc',
    'pl',
    'pt',
    'ptBR',
    'ro',
    'ru',
    'sk',
    'sl',
    'sq',
    'sr',
    'srLatn',
    'sv',
    'ta',
    'te',
    'th',
    'tr',
    'ug',
    'uk',
    'uz',
    'uzCyrl',
    'vi',
    'zhCN',
    'zhHK',
    'zhTW',
]