import { useLocation, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import { ImageType } from "../../types"
import { Image } from "../../components"

//Choisir une image pour l'en-tête: mane, folder, title (480 x 320)
const gites: {ns: string, img: ImageType}[] = [
    {ns: 'closduparc', img: {
        name: 'closduparc-facade_vignette.jpg',
        folder: 'closduparc',
        title: 'Le Clos du parc'
    }},
    {ns: 'gueauloup', img: {
        name: 'gueauloup-facade_vignette.png',
        folder: 'gueauloup',
        title: 'Le Gué au Loup'
    }},
    {ns: 'chevelliere', img: {
        name: 'salon-cheminee.jpg',
        folder: 'chevelliere',
        title: 'La Chevellière'
    }},
    {ns: 'gitesduparc', img: {
        name: 'gitesduparc-facades_vignette.png',
        folder: 'gitesduparc',
        title: 'Les Gîtes du Parc'
    }},
    {ns: 'chenilliere', img: {
        name: 'chenilliere-facade_vignette.png',
        folder: 'chenilliere',
        title: 'La Chenillière'
    }}
]

const GiteMenu = () => {
    const { t } = useTranslation(['closduparc','gueauloup', 'gitesduparc', 'chenilliere', 'chevelliere', 'nav']);
    const navigate = useNavigate();
    const location = useLocation();

    const title = () => {
        const view = location.pathname.substring(1)
        return (view === 'closduparc') || (view === 'gueauloup') || (view === 'gitesduparc') || (view === 'chenilliere') || (view === 'chevelliere')
        ? t('ourOthergites', {ns: 'nav'})
        : t('ourgites', {ns: 'nav'})
    }

    return (

        <nav className='flex flex-col h-fit border-y border-lodgeGrey sm:mx-4 lg:mx-6 mb-8 pb-4'>

            <div className='font-NotoSans text-center text-lodgeGrey uppercase text-2xl pt-4'>{title()}</div>

            <div className="w-full h-full flex flex-wrap justify-around">

                {gites.filter(e => e.ns !== location.pathname.substring(1)).map((e, k) => (
                <button
                    key={k}
                    className='relative flex items-center justify-center group my-2'
                    onClick={() => {
                        navigate('/'+ e.ns)
                        window.scrollTo({top: 0, left: 0, behavior: 'auto'})
                    }}
                >
                    <div className="w-full h-full absolute flex flex-col items-center justify-center bg-transparent font-semibold text-white hover:text-lodgeRed">
                        <div className="text-xl lg:text-3xl">{t('title', {ns: e.ns})}</div>
                        <div className="text-lg lg:text-xl">{t('body', {ns: e.ns})}</div>
                    </div>

                    <Image
                        name={e.img.name}
                        folder={e.img.folder}
                        title={e.img.title}
                        className="w-[180px] h-[120px] lg:w-[240px] lg:h-[160px] object-cover shadow-lg shadow-gray-600 group-hover:shadow-white"
                    />
                </button>
                ))}

            </div>

        </nav>

    );
}

export default GiteMenu