export const Books = () => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" className="w-10 h-10 stroke-white">
            <g>
                <g>
                    <path fill="#fff" d="M229.2,91.5c-1-7.2-1.1-19.5,5.6-23.7c0.1-0.1,0.2-0.2,0.3-0.3c5.4-1.7,9.2-4.2,6.3-7.5l-80.4-24.2L30.8,54c0,0-14.9,2.1-13.6,25.3c0.7,12.6,4.7,18.8,8.1,21.8l-10.7,3.2c-3,3.3,0.8,5.7,6.3,7.5c0.1,0.1,0.2,0.2,0.3,0.3c6.7,4.1,6.5,16.5,5.6,23.7c-23.9,6.8-15.3,9-15.3,9l5.2,1.3c-3.7,3.3-7.4,9.7-6.7,21.7c1.3,23.1,13.6,24.8,13.6,24.8l85.1,27.7L237.3,189c0,0,8.6-2.2-15.3-9c-1-7.2-1.1-19.5,5.6-23.7c0.1-0.1,0.2-0.2,0.3-0.3c5.4-1.7,9.2-4.2,6.3-7.5l-5.6-1.7c3.7-2.2,9.3-8.1,10.2-23.4c0.6-10.5-2.1-16.6-5.3-20.3l11-2.7C244.5,100.5,253.1,98.3,229.2,91.5z M121.7,91.3l21.6-4.4l73-15l10.9-2.2c-3.5,6.4-3.3,15.1-2.8,20.7c0.1,1.3,0.3,2.4,0.4,3.3l-11.9,3l-92.2,22.9L121.7,91.3z M28.8,113.8l10.9,2.2l70.1,14.4l6.1,1.3l0,0l0,0l18.4,3.8l0.9,28.2l-94.8-23.6l-9.3-2.3c0.1-0.9,0.3-2,0.4-3.3C32.1,128.8,32.3,120.1,28.8,113.8z M24.6,78.2c-0.1-6.2,1-10.6,3.2-12.9c1.4-1.4,3.1-1.7,4.2-1.7c0.6,0,1,0.1,1.1,0.1l56.1,18.3l28,9.1l-0.9,28.4l-78.5-22l-4.1-1.2c-0.1,0-0.4-0.1-0.5-0.1C32.7,96.1,25,95.4,24.6,78.2z M109,207.8l-82.6-23.1c-0.1,0-0.4-0.1-0.5-0.1c-0.3,0-8.1-0.7-8.5-18c-0.1-6.2,1-10.6,3.2-12.9c1.4-1.4,3.1-1.7,4.2-1.7c0.6,0,1,0.1,1.1,0.1l0,0l84,27.4L109,207.8z M217.3,178.7c0.1,1.3,0.3,2.4,0.4,3.3l-104.1,25.9l0.9-28.2l22.3-4.6l3.3,0.8l12.3-4l56.8-11.7L220,158C216.5,164.3,216.7,173.1,217.3,178.7z M223,140.4c-0.2,0-0.4,0-0.6,0.1l-7.9,2.2l-74.8,20.9l-0.9-28.3l24.5-8l59.4-19.4c0,0,3.1-0.7,5.4,1.6c2.2,2.3,3.3,6.7,3.2,12.9C231,139.6,223.3,140.3,223,140.4z"/>
                </g>
            </g>
        </svg>
    )
}