import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addDays, format } from 'date-fns';
import "./datePicker.css";
import { Calendar } from "../assets/icons/Calendar";

type DatePickerProps = {
    startDate?: Date;
    setStartDate: (d: Date | undefined) => void
    lang: Locale
    night: number
}

type HighlightDates = {[className: string]: Date[]}

const Datepicker = ({ setStartDate, startDate, lang, night }: DatePickerProps) => {
    const { t } = useTranslation('nav');
    const [highlightDates, setHighlightDates] = useState<HighlightDates[] | undefined>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (startDate) {
            const dates: Date[] = []
            for(let i = 1; i < night; i++) {
                dates.push(addDays(startDate, i))
            }

            const highlightDates: HighlightDates[] = [
                {
                    "react-datepicker__day--highlighted": dates,
                },
                {
                    "react-datepicker__day--highlighted-end": [
                        addDays(startDate, night)
                    ],
                },
            ];

            setHighlightDates(highlightDates)
        }

    },[startDate, night])

    const handleClick = (e: any) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <div className="my-1">

            {!startDate && !isOpen &&
            <button
                className="flex flex-wrap items-center border border-lodgeGrey rounded-lg px-2 py-1"
                onClick={handleClick}
            >
                <div className="mr-2"><Calendar /></div>
                <div className="text-left pt-1">{t('booking.calendar')}</div>
            </button>
            }

            {isOpen &&
            <>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date as Date)}
                    highlightDates={highlightDates}
                    closeOnScroll={true}
                    isClearable={true}
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 365)}
                    inline
                    locale={lang}
                    className="float"
                />

                {startDate &&
                <div className="flex flex-wrap text-white">
                    <button
                        className="flex flex-row items-center bg-success rounded-full px-2 mr-2 mt-1"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <div className="mr-2"><Calendar /></div>
                        <div className="text-left pt-1.5">{t('confirm')}</div>
                    </button>

                    <button
                        className="flex flex-row items-center bg-warning rounded-full px-2 mt-1"
                        onClick={() => {
                            setStartDate(undefined)
                            setHighlightDates(undefined)
                            setIsOpen(!isOpen)
                        }}
                    >
                        <div className="text-white text-left pt-1.5">{t('booking.clear')}</div>
                    </button>

                </div>
                }
            </>
            }

            {startDate && !isOpen &&
            <div className="flex flex-wrap items-center">
                <div className="flex flex-row mr-3">
                    <span className="mr-1">{t('booking.startDate')}</span>
                    <span>{startDate && format(startDate, 'EEEE', {locale: lang} ) + ' ' + format(startDate, 'PP', {locale: lang} )}</span>
                </div>

                <button
                    className="flex flex-row items-center bg-success border rounded-full text-white px-2"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="mr-2"><Calendar /></div>
                    <div className="text-left pt-1.5">{t('modify')}</div>
                </button>
            </div>
            }
        </div>
    )
}

export { Datepicker }