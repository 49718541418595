import { MutableRefObject } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { EnvelopeIcon, XMarkIcon } from "@heroicons/react/24/solid"

type MenuProps = {
    refMenu: MutableRefObject<HTMLDivElement>
    open: boolean;
    setOpenMenu: (m: boolean) => void
    setOpenBook: (b: boolean) => void
}

const Menu = ({refMenu, setOpenBook, setOpenMenu, open}: MenuProps) => {
    const { t } = useTranslation(['nav'])
    const navigate = useNavigate();

    return (
        <nav ref={refMenu}
            className={[
                'h-screen w-56 fixed flex flex-col bg-lodgeGrey bg-opacity-80 backdrop-blur-sm text-white z-40 py-2',
                !open ? 'animate-closemenu -right-56' : 'animate-openmenu right-0',
            ].join(' ')}
        >

            <button className="place-self-end mr-4" onClick={() => setOpenMenu(false)} ><XMarkIcon className="h-8 w-8" /></button>

            <div className="flex flex-col justify-center font-NotoSerif">

                <button
                    className='w-fit h-8 place-self-center flex flex-row items-center justify-center bg-lodgeRed text-white border rounded-full px-2 mt-4'
                    onClick={() => setOpenBook(true)}
                >
                    <EnvelopeIcon className='w-4 h-4' />
                    <div className='h-5 font-NotoSans my-auto ml-1'>{t('booking.title')}</div>
                </button>

                <button
                    className="mt-4"
                    onClick={() => {navigate('/'); setOpenMenu(false)}}
                >
                    {t('lodgepark')}
                </button>

                <div className="border-b mx-10 my-4" />

                <div className="font-NotoSans text-center text-lg">{t('ourgites')}</div>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/closduparc'); setOpenMenu(false)}}
                >
                    {t('gites.closduparc')}
                </button>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/gueauloup'); setOpenMenu(false)}}
                >
                    {t('gites.gueauloup')}
                </button>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/chevelliere'); setOpenMenu(false)}}
                >
                    {t('gites.chevelliere')}
                </button>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/gitesduparc'); setOpenMenu(false)}}
                >
                    {t('gites.gitesduparc')}
                </button>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/chenilliere'); setOpenMenu(false)}}
                >
                    {t('gites.chenilliere')}
                </button>

                <div className="border-b mx-10 my-4" />

                <button
                    className="mt-2"
                    onClick={() => {navigate('/faq'); setOpenMenu(false)}}
                >
                    {t('faq')}
                </button>

                <div className="border-b mx-10 my-4" />

                <div className="font-NotoSans text-center text-lg">{t('discover')}</div>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/culture'); setOpenMenu(false)}}
                >
                    {t('experiences.culture')}
                </button>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/nature'); setOpenMenu(false)}}
                >
                    {t('experiences.nature')}
                </button>

                <button
                    className="mt-2"
                    onClick={() => {navigate('/artdevivre'); setOpenMenu(false)}}
                >
                    {t('experiences.artdevivre')}
                </button>


            </div>

        </nav>
    );
}

export default Menu