export const Games = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 294.374 134.93124999999998" enableBackground="new 0 0 294.374 107.945" fill="#fff" className="w-14 h-14 stroke-white">
            <g>
                <path d="M64.686,100.226c1.439-4.178,0.059-8.835-3.424-11.556L40.693,72.606c3.215-5.311,7.329-10.33,11.493-14.905l-7.578-17.619   c-1.962-4.559,0.151-9.862,4.712-11.824c1.132-0.486,2.326-0.733,3.551-0.733c3.602,0,6.848,2.138,8.273,5.444l11.515,26.772   l3.653,4.784c1.133,1.486,2.822,2.458,4.677,2.694l25.499,3.25c0.302,0.038,0.602,0.057,0.897,0.057   c3.471,0,6.482-2.581,6.933-6.114c0.488-3.836-2.223-7.34-6.057-7.828l-22.597-2.882l-6.705-8.778   c-1.262-0.478-2.49-1.057-3.66-1.756c-7.015-4.193-10.971-11.575-11.139-19.202c-0.871-0.583-1.824-1.105-2.844-1.553   c-1.1-0.749-2.376-1.163-3.676-1.212c-5.652-1.286-12.514-0.379-18.818,4.475C25.002,36.452,18.299,44.79,10.869,61.159   C6.677,72.96,13.529,79.021,20.34,83.04l4.393,3.432H10.376C4.644,86.471,0,91.117,0,96.848c0,5.731,4.644,10.378,10.376,10.378   l44.501-0.002C59.293,107.224,63.246,104.402,64.686,100.226z"/>
                <path d="M59.307,33.759c-1.528-3.551-5.646-5.19-9.196-3.665c-3.55,1.528-5.192,5.647-3.665,9.198l26.667,61.999   c1.139,2.65,3.72,4.236,6.433,4.236c0.923,0,1.861-0.184,2.764-0.572c3.551-1.525,5.192-5.643,3.664-9.196L59.307,33.759z"/>
                <ellipse transform="matrix(0.513 -0.8584 0.8584 0.513 22.2204 86.1697)" cx="87.054" cy="23.501" rx="20.911" ry="20.909"/>
                <path d="M283.997,86.472h-17.164l6.74-6.286c1.059-0.988,1.863-2.146,2.414-3.393c0.502-0.735,1.014-1.547,1.553-2.427   c4.279-6.977,8.168-16.082-1.73-24.791c-14.453-10.688-24.365-14.713-41.684-17.406c-12.609-1.881-21.537,5.785-23.951,13.161   c-1.715,5.242,1.17,10.561,5.965,13.815c0.615-4.642,0.674-8.054,0.666-9.454c0-0.732-0.018-1.179-0.021-1.313   c-0.275-4.954,3.531-9.211,8.486-9.486c0.17-0.009,0.338-0.015,0.506-0.015c4.77,0,8.715,3.735,8.979,8.504   c0.006,0.129,0.047,0.924,0.049,2.293c-0.018,3.424-0.275,8.864-1.395,15.484c5.986,1.861,12.412,4.09,18.326,7.017l-18.316,17.085   c-3.115,2.904-4.137,7.429-2.576,11.39c1.563,3.965,5.393,6.573,9.654,6.573h43.5c5.73,0,10.377-4.646,10.377-10.375   C294.374,91.118,289.728,86.472,283.997,86.472z"/>
                <path d="M232.759,47.493c-0.215-3.861-3.517-6.817-7.377-6.603c-3.861,0.215-6.814,3.517-6.602,7.378l-0.002-0.002   c0,0,0.002,0,0.002,0.026c0.008,0.142,0.023,0.619,0.023,1.387c0.037,5.695-1.111,27.106-14.521,45.959   c-2.234,3.156-1.484,7.525,1.672,9.759c1.227,0.868,2.639,1.286,4.037,1.286c2.195,0,4.357-1.029,5.721-2.958   c15.961-22.677,17.057-46.812,17.094-54.046C232.804,48.372,232.765,47.611,232.759,47.493z"/>
                <ellipse transform="matrix(0.5005 -0.8658 0.8658 0.5005 79.6726 179.9095)" cx="195.74" cy="20.913" rx="20.909" ry="20.911"/>
                <rect x="115.874" y="94.527" width="13" height="13"/>
                <rect x="115.874" y="77.027" width="49.5" height="13"/>
                <rect x="115.874" y="41.694" width="49.5" height="13"/>
                <rect x="134.124" y="94.527" width="13" height="13"/>
                <rect x="152.374" y="94.527" width="13" height="13"/>
                <rect x="115.874" y="59.195" width="13" height="13"/>
                <rect x="134.124" y="59.195" width="13" height="13"/>
                <rect x="183.624" y="94.945" width="13" height="13"/>
            </g>
        </svg>
    )
}