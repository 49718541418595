import { useState, useEffect, RefObject } from "react";

export type DimElement = {width: number, height: number}

export function useDimElement<T extends HTMLElement>(ref: RefObject<T>): DimElement {
    const [dim, setDim] = useState<DimElement>({width: 0, height: 0})

    useEffect(() => {
        const element = ref.current
        const resize = new ResizeObserver((entries) => {
            setDim({
                width: entries[0].contentRect.width,
                height: entries[0].contentRect.height,
            })
        })

        if (element) resize.observe(element)

        return () => {element && resize.unobserve(element)}

    }, [ref])

    return dim
}