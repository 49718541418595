import { ChangeEvent, useState } from 'react';

type TAny<T> = T extends any ? T : Record<string, any>

export const useForm = <T= void>(initialState: TAny<T>) => {
	const [data, setData] = useState< TAny<T>>(initialState)

	const onChange = (event: ChangeEvent<any>): void => {
		let { name, value, checked, type } = event.target
		let v: string | number | boolean | undefined
		switch (type) {
			case 'checkbox':
				v = checked
				break
			default:
				v = value
		}

		setData(prevState => ({ ...prevState, [name]: v }))
	}

	const clear = (name: string, value?: any): void => {
		setData(prevState => ({ ...prevState, [name]: value }))
	}

	const reset = (): void => {
		setData(initialState)
	}

	return {
		data,
		setData,
		onChange,
		clear,
		reset,
	}
}