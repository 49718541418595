import { ChangeEvent, KeyboardEvent, useRef } from "react";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import { BaseContainerLabel } from "./BaseContainerLabel";

interface InputNumberProps {
    id?: string
    name: string
    label: string
    value: number | ''
    setValue: (v: number | '') => void
    onChange: (t: ChangeEvent<HTMLInputElement>) => void
    min?: number;
    max?: number;
    containerClass?: string
    isRequire?: boolean
}

const InputNumber = ({id, name, label, value, setValue, onChange, min=0, max=100, containerClass, isRequire = false }: InputNumberProps) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const isValid = () => {
        return true
    }

    const checkValue = (val: number | '') => {
        if (typeof val === 'number') {
            if (val >= max) return setValue(max)
            if (val <= min) return setValue(min)
        }

        setValue(val)
    }

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'keyup')  checkValue(typeof value === 'number' ? value + 1 : 2)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'keydown') checkValue(typeof value === 'number' ? value - 1 : 2)
    }

    return (

        <BaseContainerLabel
            id={id}
            name={name}
            label={label}
            containerRef={containerRef}
            containerClass={[
                containerClass,
                "relative h-full min-h-[2.25rem] max-w-[192px] flex items-center rounded-lg border-lodgeGrey border-x border-b mt-3",
                isValid() ? "bg-white bg-opacity-10 " : "bg-danger bg-opacity-20",
                "focus-within:bg-white bg-opacity-10 focus-within:bg-opacity-60",
            ].join(' ')}
            labelClass='scale-75 px-3 -mt-2 mb-2'
        >
            <input
                type='number'
                id={name}
                value={value}
                className="h-full w-20 outline-none shadow-none bg-transparent text-lg px-1 pt-2"
                onChange={(event) => {
                    event.stopPropagation()
                    const val = event.target.value
                    checkValue(val === '' ? '' : parseInt(val))
                }}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
            />

            <div className="flex items-center text-center">
                <button
                    type="button"
                    className={[
                        "h-6 w-6 flex justify-center items-center rounded-full bg-white ml-1 mr-3",
                        typeof value === 'number' && value <= min ? 'opacity-30' : ''
                    ].join(' ')}
                    onClick={() =>  checkValue(typeof value === 'number' ? value - 1 : 2)} //setValue(value <= min ? value : value - 1)}
                >
                    <MinusIcon className="h-5 w-5 text-lodgeGrey" strokeWidth={3} />
                </button>

                <button
                    type="button"
                    className={[
                        "h-6 w-6 flex justify-center items-center bg-white rounded-full mr-1",
                        typeof value === 'number' && value >= max ? 'opacity-30' : ''
                    ].join(' ')}
                    onClick={() => checkValue(typeof value === 'number' ? value + 1 : 2)} //setValue(value >= max ? value : value + 1)}
                >
                    <PlusIcon className="h-5 w-5 text-lodgeGrey" strokeWidth={3}/>
                </button>
            </div>

        </BaseContainerLabel>
    )
}

export { InputNumber }