import { useEffect, useState } from "react";

export type DimWindow = {width: number, height: number}

export const useDimWindow = () => {
    const [dim, setDim] = useState<DimWindow>({width: window.innerWidth, height: window.innerHeight})

    useEffect(() => {
        const onResize = () => {
            setDim({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener("resize", onResize);
    
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    
    return dim;
}