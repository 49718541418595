import { useEffect } from "react"
import { useLocation } from "react-router"
import { useTranslation } from "react-i18next"
import { addDays, format } from 'date-fns'
import parsePhoneNumber  from  'libphonenumber-js'
import { BookType } from "../types"
import { lodgeGites, lodgeParkAddress } from "../parameters"
import { useForm, useNavGetLocale, useNavLocaleDate } from "../utils";
import { Datepicker, ModalBase, InputText, InputTel, CardLabel, InputTextarea, InputNumber, Form } from "../components";
import { InputCheckbox } from "../components/InputCheckBox";

type BookProps = {
    open: boolean
    setOpen: (o: boolean) => void
}

const Booking = ({open, setOpen}: BookProps) => {
    const { t } = useTranslation(['nav'])
    const location = useLocation()
    const locale = useNavGetLocale()
    const lang = useNavLocaleDate()
    const {data, setData, onChange} = useForm<BookType>({
        name: '',
        phone: {num: '', countryCode: locale.countryCode},
        startDate: undefined,
        night: 2,
        bookGites: [],
        message: ''
    })

    useEffect(() => {
        if (open)
        switch(location.pathname) {
            case '/closduparc': setData({...data, bookGites: ['closduparc']}) //data.bookGites.push('closduparc')
                break
            case '/gueauloup': setData({...data, bookGites: ['gueauloup']}) //data.bookGites.push('gueauloup')
                break
            case '/chevelliere': setData({...data, bookGites: ['chevelliere']}) //data.bookGites.push('gitesduparc')
                break
            case '/gitesduparc': setData({...data, bookGites: ['gitesduparc']}) //data.bookGites.push('gitesduparc')
                break
            case '/chenilliere': setData({...data, bookGites: ['chenilliere']}) //data.bookGites.push('chenilliere')
                break
        }
    },[open, location.pathname, data, setData])

    const formBook = () => {
        mailto()
        setOpen(false)
    }

    const mailto = () => {
        const phoneIntl = data.phone.countryCode ? parsePhoneNumber(data.phone.num, data.phone.countryCode)?.formatInternational() : data.phone.num

        const n = 'De ' + data.name + ' (' + phoneIntl + ')\n'
        const g = data.bookGites.length > 0 ? 'Gîte(s): ' + data.bookGites.map(g => lodgeGites.find(lg => g === lg.id)?.label).toString() +'\n' : 'Pas de gîte spécifié.\n'
        const d = data.startDate && data.night
        ?   'Du '
            + format(data.startDate, 'EEEE', {locale: lang})
            + ' '
            + format(data.startDate, 'PP', {locale: lang})
            + ' au '
            + format(addDays(data.startDate, data.night), 'EEEE', {locale: lang})
            + ' '
            + format(addDays(data.startDate, data.night), 'PP', {locale: lang})
            +'\n'
        :   "Pas de date d'arrivée spécifiée\n"
        const nt = typeof data.night === 'number' ? 'Pour ' + data.night + ' nuits.\n' : 'Nombre de jour non spécifié'
        const m = '\nMessage:\n' + data.message

        const body = encodeURIComponent(n + g + d + nt + m)

        window.location.href='mailto:' + lodgeParkAddress.mail + '?subject=LodgePark-Réservation&body=' + body
    }

    return (
        <ModalBase
            open={open}
            onClose={() => {
                setData({...data, bookGites: []})
                setOpen(false)
            }}
            containerClass="h-[95%] w-screen min-w-[310px] sm:w-3/5 bg-transparent text-lodgeGrey"
        >
            <Form
                id='formBook'
                onSubmit={formBook}
                onCancel={() => {
                    setData({...data, bookGites: []})
                    setOpen(false)
                }}
                containerClass="bg-white bg-opacity-70 border-4 border-lodgeGreyLight rounded-lg font-NotoSans overflow-y-scroll no-scrollbar m-4 p-2"
            >
                <div className="flex flex-col justify-center my-4 mx-2">
                    <div className="text-lodgeRed text-xl text-center">{t('booking.text1')}</div>
                    <div className="text-lodgeRed text-xl text-center">{t('booking.text2')}</div>
                    <div className="italic my-2 text-justify">{t('booking.text3')}</div>
                </div>

                <div>
                    <InputText
                        label={t('booking.namePlaceholder')}
                        id='name'
                        name='name'
                        text={data.name}
                        onChange={onChange}
                        isRequire={true}
                        autofocus={true}
                    />

                    <InputTel
                        id="phone"
                        name='phone'
                        label={t('booking.phonePlaceholder')}
                        phone={data.phone}
                        setPhone={p => setData({...data, phone: p})}
                        onChange={onChange}
                        isRequire={true}
                    />

                    <CardLabel label={t('booking.questionDates')} containerClass="h-fit mt-3 py-2" >
                        <div className="flex flex-col">
                            <Datepicker
                                startDate={data.startDate}
                                setStartDate={sd => setData({...data, startDate: sd})}
                                lang={lang}
                                night={typeof data.night === 'number' ? data.night : 2}
                            />

                            <InputNumber
                                name="night"
                                label={t('booking.nights')}
                                value={data.night}
                                setValue={v => setData({...data, night: v})}
                                onChange={onChange}
                                min={2}
                                containerClass="w-80"
                            />

                            {data.startDate &&
                            <div className="flex flex-row mt-2">
                                <span className="mr-1">{t('booking.endDate')}</span>
                                <span>{data.startDate && format(addDays(data.startDate, typeof data.night === 'number' ? data.night : 2 ), 'EEEE', {locale: lang} ) + ' ' + format(addDays(data.startDate, typeof data.night === 'number' ? data.night : 2), 'PP', {locale: lang} )}</span>
                            </div>
                            }
                        </div>
                    </CardLabel>

                    <CardLabel label={t('booking.questionGites')} containerClass="h-fit mt-3 py-2" >
                        <InputCheckbox
                            id='bookGites'
                            items={lodgeGites}
                            checkedId={data.bookGites}
                            setCheckedId={c => setData({...data, bookGites: c})}
                        />
                    </CardLabel>

                    <InputTextarea
                        name="message"
                        text={data.message}
                        setText={t => setData({...data, message: t})}
                        onChange={onChange}
                        label={t('booking.messagePlaceholder')}
                        minLength={4}
                        containerClass="mt-3"
                        isRequire={true}
                    />

                </div>
            </Form>

        </ModalBase>
    )
}

export default Booking
