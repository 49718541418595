export const Fireplace = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" x="0px" y="0px" strokeWidth="0px" className="w-10 h-12 stroke-white">
            <rect fill="#fff" x="4" y="4" width="56" height="6" rx="1" ry="1"/>
            <rect fill="#fff" x="4" y="54" width="56" height="6" rx="1" ry="1"/>
            <path fill="#fff" d="m25.76,51.34c2.3,2.61,6.37,3.26,9.66,2.11,8.6-3.02,2.79-15.32,2.79-15.32-.05,2.38-1.8,4.17-1.8,4.17.19-1.91-.52-4.26-2.92-6.45-1.65-1.51-.63-6.3-.63-6.3-2.79,1.25-4.18,5.54-4.12,8.2.06,2.66-.33,4.76-.33,4.76-1.62-4.23-4.54-4.98-4.54-4.98,0,0,1.53,1.02.52,7.62-.52,3.37.13,4.8,1.37,6.2Zm4.17-5.57s.23-1.21.19-2.74.77-3.99,2.37-4.71c0,0-.59,2.75.36,3.62,1.38,1.26,1.79,2.61,1.68,3.71,0,0,1.01-1.03,1.04-2.4,0,0,3.34,7.07-1.6,8.81-1.89.66-4.23.29-5.55-1.21-.71-.81-1.09-1.63-.79-3.57.58-3.79-.3-4.38-.3-4.38,0,0,1.67.43,2.61,2.86Z"/>
            <polygon fill="#fff" points="7.08 12 9.25 16 54.75 16 56.92 12 7.08 12"/>
            <path fill="#fff" d="m55,52V18H9v34h8v-24c0-2.21,1.79-4,4-4h22c2.21,0,4,1.79,4,4v24h8Z"/>
        </svg>
    )
}