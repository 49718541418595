import { ChangeEvent, FormEvent, ReactNode } from "react"
import { useTranslation } from "react-i18next"

type FormProps = {
    id?: string
    children: ReactNode
    onSubmit: (d: any) => void
    changeForm?: (c: any ) => void
    onCancel?: () => void
    showIf?: boolean
    showButton?: boolean
    containerClass?: string

    autocomplete?: 'on' | 'off'
    showErrorMessage?: boolean
}

const Form = ({id, children, onSubmit, showIf= true, changeForm, onCancel, showButton= true, containerClass, autocomplete= 'off', showErrorMessage= true}: FormProps) => {
    const {t} = useTranslation(["nav"])
    const change = (event: ChangeEvent<HTMLFormElement>) => {
        changeForm && changeForm(event)
    }

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const target = event.currentTarget
        if (!target.checkValidity() && showErrorMessage) {
            return target.reportValidity()
        }

        return onSubmit(event.currentTarget.elements)
    }

    return (
        <form
            id={id}
            onSubmit={submit}
            onChange={change}
            className={['group ' + containerClass, showIf ? '' : 'hidden'].join(' ')}
            autoComplete={autocomplete}
            noValidate={true}
        >
            {children}

            {showButton &&
            <div className= "flex justify-between mt-5 mb-3 px-1">
                <button
                    onClick={onCancel}
                    className="w-fit h-8 flex flex-row items-center bg-lodgeGreyLight hover:bg-opacity-60 text-lodgeGrey rounded-full px-4 pt-1 mr-3"
                >
                    {t('booking.cancel')}
                </button>

                <button
                    type="submit"
                    className="w-fit h-8 flex flex-row items-center bg-success rounded-full group-invalid:opacity-40 text-white hover:opacity-60 px-4 pt-1 mr-3"
                >
                    {t('booking.send')}
                </button>
            </div>
            }
        </form>
    )
}

export { Form }
