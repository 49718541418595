import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CountryCode } from "../types";

type Country = {code: CountryCode, title: string}

export const useGetCountries = () => {
    const { t, i18n } = useTranslation(['countries'])
    const [countries, setCountries] = useState<Country[]>([])

    useEffect(() => {
        const getCountries: {[key: string]: string} = t('countries', {returnObjects: true})
        const cs: Country[] = []
        for (const i in getCountries) cs.push({code: i as CountryCode, title: getCountries[i]})
        setCountries(cs)
    }, [t, i18n.language]);

    return countries
};
