import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { SysCodeLang } from '../types'
import { lodgeParkAddress } from '../parameters'
import { useNavGetLocale } from "../utils"
import { EnvelopeIcon, PhoneIcon, Bars3Icon } from '@heroicons/react/24/solid'
import { SelectLang } from '../components/SelectLang'

type ActionsProps = {
    openMenu: boolean,
    setOpenMenu: (m: boolean) => void
    setOpenBook: (b: boolean) => void
    mobile: boolean
}

const Actions = ({openMenu, setOpenMenu, setOpenBook, mobile }: ActionsProps) => {
    const navLocale = useNavGetLocale()
    const { t, i18n } = useTranslation(['nav']);
    const [langCode, setLangCode] = useState<SysCodeLang>(navLocale.langCode)

    useEffect(() => {
        setLangCode(navLocale.langCode)
    }, [navLocale])

    useEffect(() => {
        if(langCode !== i18n.language) i18n.changeLanguage(langCode)
    }, [langCode, i18n])


    return (

        <div className={[
            "",
            mobile
            ? 'w-full h-14 fixed bottom-0 left-0 flex flex-row justify-around items-center sm:hidden z-40 bg-white bg-opacity-60 text-black backdrop-blur-sm'
            : 'hidden sm:flex justify-center items-center text-white'
        ].join(' ')}>

            <button
                className={[
                    "w-fit h-8 flex flex-row items-center rounded-full px-2 mr-4",
                    mobile
                    ?'bg-lodgeRed text-white'
                    :'border border-white'
                ].join(' ')}
                onClick={() => setOpenBook(true)}
            >
                <EnvelopeIcon className='w-4 h-4' />
                <div className='h-5 font-NotoSans my-auto ml-1'>{t('booking.title')}</div>
            </button>

            <a
                href={'tel:' + lodgeParkAddress.phone}
                className={[
                    "w-8 h-8 flex justify-center items-center border rounded-full mr-4",
                    mobile
                    ? 'border-black'
                    : 'border-white'
                ].join(' ')}
            >
                <PhoneIcon className='w-5 h-5' />
            </a>

            <SelectLang
                value={langCode}
                setValue={setLangCode}
            />

            <button
                className='flex flex-col items-center mx-4'
                onClick={() => setOpenMenu(!openMenu)}
            >
                <Bars3Icon className='w-8 h-8' />
                <div className='text-[8px] -mt-1'>MENU</div>
            </button>

        </div>
    )
}

export default Actions