import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logoLodgePark from '../assets/Lodge_Park_logo.png'

const NoMatchView = () => {
  const { t } = useTranslation('nav');
  const navigate = useNavigate()


  return (
    <div className="w-screen h-2/3 flex justify-center items-center bg-white text-lodgeGrey ">

      <div className="flex flex-col justify-center items-center mb-30">

        <img src={logoLodgePark} className='w-36 h-36' alt='Logo Lodge Park' />

        <h1 className='text-center text-2xl mt-2'>
          {t('lodgepark')}
        </h1>

        <h2 className='text-center text-xl italic mt-4'>
            {t('noMatchView')}
        </h2>

        <button
          className='w-full border border-lodgeGrey hover:bg-lodgeGreyLight rounded-lg px-3 py-2 mt-4'
          onClick={() => navigate('/')} >{t('noMatchViewBack')}
        </button>

      </div>
    </div>
  );
};

export { NoMatchView }