export const Tennis = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="1280.000000pt" height="877.000000pt" viewBox="0 0 1280.000000 877.000000"
            preserveAspectRatio="xMidYMid meet" className="w-10 h-10 stroke-white">

            <g transform="translate(0.000000,877.000000) scale(0.100000,-0.100000)"
            fill="#fff" stroke="none">
                <path d="M10885 8764 c-219 -24 -427 -68 -627 -135 -1178 -391 -2251 -1555
                -2547 -2760 -22 -90 -43 -187 -47 -216 -5 -37 -11 -53 -24 -56 -12 -3 -20 -19
                -25 -48 -18 -107 -139 -645 -194 -860 -67 -263 -155 -551 -208 -679 -45 -110
                -192 -396 -273 -530 -90 -149 -261 -410 -269 -410 -3 0 -12 10 -20 21 -12 21
                -16 18 -115 -80 -56 -55 -106 -101 -111 -101 -6 0 -46 36 -90 80 -71 72 -81
                78 -95 65 -15 -14 -19 -13 -35 5 -10 11 -24 20 -31 20 -26 0 -289 429 -413
                676 -162 321 -272 689 -447 1496 -35 163 -64 299 -64 302 0 3 -5 5 -12 3 -7
                -1 -15 18 -20 48 -160 930 -791 1878 -1669 2506 -784 560 -1723 766 -2434 534
                -118 -39 -327 -144 -430 -216 -115 -81 -292 -259 -372 -372 -190 -270 -295
                -596 -310 -967 -14 -356 68 -759 231 -1138 490 -1137 1650 -2100 2803 -2329
                86 -17 133 -31 133 -39 0 -13 13 -18 65 -28 17 -3 156 -33 310 -66 469 -102
                836 -199 1100 -290 251 -87 630 -291 978 -528 73 -50 77 -55 63 -71 -9 -10
                -16 -22 -16 -27 0 -5 47 -56 105 -114 l105 -105 -697 -697 -698 -698 -110 0
                -110 0 -98 -98 -97 -97 353 -353 352 -352 93 93 92 93 9 93 8 93 707 707 706
                706 710 -710 710 -710 0 -105 0 -105 99 -99 c86 -86 102 -98 115 -86 13 10 19
                10 34 -1 9 -8 19 -17 22 -21 6 -8 710 686 710 699 0 5 -39 47 -87 95 -79 77
                -94 87 -141 97 -29 6 -72 11 -95 11 l-43 0 -744 739 -745 739 105 107 105 106
                -28 24 -27 24 102 69 c271 181 570 351 783 445 247 109 669 227 1305 366 151
                33 292 63 312 66 23 5 35 11 32 20 -4 8 18 16 73 25 43 7 139 27 213 45 814
                201 1640 775 2212 1536 671 894 859 1912 490 2654 -234 471 -664 777 -1222
                870 -92 16 -422 28 -505 19z m272 -280 l30 -6 -59 -56 -59 -56 -54 54 c-30 30
                -55 58 -55 62 0 9 154 11 197 2z m-266 -109 l95 -95 -175 -175 -176 -175 -167
                168 c-93 92 -168 171 -168 177 0 6 21 32 48 57 40 40 60 51 132 71 47 14 123
                32 170 41 47 8 94 17 105 20 11 2 25 4 31 5 5 0 53 -42 105 -94z m554 71 c96
                -21 205 -57 295 -99 129 -60 135 -38 -65 -237 l-175 -175 -172 172 -173 173
                94 94 c57 57 100 92 110 90 9 -2 48 -10 86 -18z m-9605 1 c0 -2 -8 -11 -18
                -21 -18 -15 -20 -15 -37 4 -18 20 -18 20 18 20 21 0 37 -2 37 -3z m-217 -85
                l67 -67 -155 -155 -155 -155 -145 145 c-80 80 -145 147 -145 150 0 22 314 127
                425 143 17 2 32 5 35 6 3 0 36 -29 73 -67z m667 22 c112 -31 239 -76 249 -88
                8 -9 -29 -52 -144 -166 l-155 -155 -152 152 -153 153 74 74 73 73 76 -14 c43
                -8 102 -21 132 -29z m7670 -279 l-175 -175 -52 52 c-29 29 -53 55 -53 58 0 13
                417 238 445 239 5 1 -69 -78 -165 -174z m2072 56 c89 -72 119 -103 196 -206
                l61 -80 -182 -182 -182 -183 -172 172 -173 173 182 182 c101 101 186 183 190
                183 4 0 40 -27 80 -59z m-622 -305 c0 -14 -331 -346 -345 -346 -5 0 -85 75
                -177 167 l-168 168 176 176 177 177 168 -166 c93 -91 169 -170 169 -176z
                m-10305 -146 l-155 -155 -162 162 -162 162 29 41 c40 58 160 180 225 227 l55
                41 163 -161 162 -162 -155 -155z m9280 310 l170 -170 -173 -173 -172 -172
                -172 172 -173 173 170 170 c93 93 172 170 175 170 3 0 82 -77 175 -170z
                m-8410 -10 l150 -150 -155 -155 -156 -156 -152 153 -152 153 153 153 c83 83
                154 152 157 152 3 0 73 -68 155 -150z m845 -305 l-155 -155 -150 150 -150 150
                155 155 155 155 150 -150 150 -150 -155 -155z m243 344 c31 -18 57 -37 57 -40
                0 -4 -7 -12 -16 -20 -13 -11 -25 -3 -82 56 -37 38 -56 62 -42 53 14 -8 51 -30
                83 -49z m6582 -139 l60 -60 -173 -173 -172 -172 -85 85 c-47 46 -85 87 -85 90
                2 17 362 290 384 290 6 0 38 -27 71 -60z m-6292 -52 c92 -69 251 -197 255
                -206 1 -4 -19 -29 -46 -55 l-47 -47 -149 149 -148 148 29 30 c15 17 30 31 32
                32 2 0 35 -23 74 -51z m8322 -258 l170 -170 -178 -178 -177 -177 -172 173
                -173 172 175 175 c96 96 177 175 180 175 3 0 82 -77 175 -170z m-1713 -7 l168
                -168 -172 -172 -173 -173 -172 172 -173 173 167 167 c92 93 172 168 178 168 5
                0 85 -75 177 -167z m853 -3 l170 -170 -173 -173 -172 -172 -172 172 -173 173
                170 170 c93 93 172 170 175 170 3 0 82 -77 175 -170z m1564 121 c57 -133 94
                -257 116 -391 l14 -85 -77 -78 -77 -77 -172 172 -173 173 167 167 c92 93 171
                168 174 168 4 0 16 -22 28 -49z m-11689 -126 l145 -145 -155 -155 -155 -155
                -72 72 -71 73 18 80 c21 100 39 158 80 259 37 94 48 116 58 116 4 0 72 -65
                152 -145z m855 -5 l150 -150 -150 -150 c-82 -82 -154 -150 -160 -150 -5 0 -76
                66 -157 147 l-148 148 152 152 c84 84 155 153 158 153 3 0 73 -67 155 -150z
                m858 2 l147 -147 -155 -155 -155 -155 -150 150 -150 150 152 152 c84 84 155
                153 158 153 3 0 72 -66 153 -148z m850 0 l147 -147 -155 -155 -155 -155 -147
                147 -148 148 150 153 c83 85 152 155 155 156 3 0 72 -65 153 -147z m5937 -77
                l85 -85 -173 -173 -172 -172 -86 86 -86 86 95 104 c97 108 232 239 245 239 4
                0 46 -38 92 -85z m-5344 -77 c50 -51 114 -120 143 -152 l52 -59 -42 -43 c-24
                -24 -48 -44 -54 -44 -6 0 -75 65 -155 145 l-144 145 49 50 c27 27 52 50 55 50
                3 0 46 -42 96 -92z m5682 -605 l-173 -173 -167 167 c-93 92 -168 172 -168 178
                0 5 75 85 167 177 l168 168 172 -172 173 -173 -172 -172z m1022 172 c0 -5 -75
                -85 -167 -177 l-168 -168 -172 172 -173 173 172 172 173 173 167 -167 c93 -92
                168 -172 168 -178z m688 -172 l-173 -173 -172 172 -173 173 172 172 173 173
                172 -172 173 -173 -172 -172z m866 348 l168 -169 -178 -176 -179 -176 -170
                170 -170 170 175 175 c96 96 177 175 180 175 3 0 81 -76 174 -169z m-10844
                -166 c0 -6 -68 -78 -150 -160 l-150 -150 -152 153 -153 152 155 155 155 155
                147 -147 c82 -81 148 -152 148 -158z m713 147 l147 -147 -155 -155 -155 -155
                -150 150 -150 150 152 152 c84 84 155 153 158 153 3 0 72 -66 153 -148z m857
                3 l145 -145 -155 -155 -155 -155 -149 149 -149 149 154 151 c85 83 156 150
                159 151 3 0 70 -65 150 -145z m853 -3 l147 -147 -155 -155 -155 -155 -150 150
                -150 150 152 152 c84 84 155 153 158 153 3 0 72 -66 153 -148z m5090 -80 l77
                -77 -173 -173 -172 -172 -54 54 -53 54 63 93 c101 152 214 299 228 299 4 0 41
                -35 84 -78z m3742 -140 c-3 -3 -22 11 -43 31 l-36 37 39 40 40 41 3 -72 c2
                -39 0 -74 -3 -77z m-8312 82 c21 -27 74 -102 119 -168 74 -109 79 -119 64
                -134 -15 -15 -28 -5 -166 133 l-150 149 37 38 c21 21 42 37 48 35 5 -2 27 -26
                48 -53z m-3810 18 c16 -17 15 -20 -8 -42 l-25 -23 0 41 c0 46 8 52 33 24z
                m12092 -353 c-33 -189 -106 -436 -132 -446 -10 -4 -71 50 -184 163 l-169 169
                177 177 178 178 73 -73 73 -73 -16 -95z m-3380 61 l170 -170 -173 -173 -172
                -172 -172 172 -173 173 170 170 c93 93 172 170 175 170 3 0 82 -77 175 -170z
                m858 -342 c-92 -93 -172 -168 -178 -168 -5 0 -85 75 -177 167 l-168 168 172
                172 173 173 172 -172 173 -173 -167 -167z m852 342 l170 -170 -173 -173 -172
                -172 -172 172 -173 173 170 170 c93 93 172 170 175 170 3 0 82 -77 175 -170z
                m850 0 l170 -170 -173 -173 -172 -172 -170 170 -170 170 167 171 c93 94 170
                172 173 172 3 1 82 -75 175 -168z m-10970 -10 l150 -151 -157 -155 -157 -155
                -30 28 c-38 37 -76 163 -111 373 l-10 55 77 77 c43 43 80 78 83 78 3 0 73 -68
                155 -150z m845 -305 l-155 -155 -150 150 -150 150 155 155 155 155 150 -150
                150 -150 -155 -155z m850 0 l-155 -155 -150 150 -150 150 155 155 155 155 150
                -150 150 -150 -155 -155z m860 0 l-155 -155 -150 150 -150 150 155 155 155
                155 150 -150 150 -150 -155 -155z m850 0 l-155 -155 -150 150 -150 150 155
                155 155 155 150 -150 150 -150 -155 -155z m4285 185 l39 -40 -129 -130 c-71
                -71 -130 -128 -132 -126 -8 7 164 336 176 336 4 0 25 -18 46 -40z m298 -298
                l167 -167 -173 -172 -172 -173 -173 172 -172 172 167 168 c92 92 172 168 178
                168 6 0 86 -75 178 -168z m852 -2 l170 -170 -173 -173 -172 -172 -172 172
                -173 173 170 170 c93 93 172 170 175 170 3 0 82 -77 175 -170z m850 0 l170
                -170 -173 -173 -172 -172 -172 172 -173 173 170 170 c93 93 172 170 175 170 3
                0 82 -77 175 -170z m858 2 l167 -168 -172 -172 -173 -172 -170 170 -171 170
                171 170 c93 93 172 170 175 170 3 0 81 -76 173 -168z m852 -2 l170 -170 -173
                -173 -172 -172 -172 172 -173 173 170 170 c93 93 172 170 175 170 3 0 82 -77
                175 -170z m-10972 -8 l147 -147 -155 -155 -155 -155 -147 145 c-81 80 -149
                146 -152 148 -6 3 301 312 309 312 3 0 72 -66 153 -148z m850 0 l147 -147
                -155 -155 -155 -155 -150 150 -150 150 152 152 c84 84 155 153 158 153 3 0 72
                -66 153 -148z m852 -302 c-82 -82 -154 -150 -160 -150 -6 0 -75 65 -155 145
                l-145 145 155 155 155 155 150 -150 150 -150 -150 -150z m858 302 l147 -147
                -155 -155 -155 -155 -150 150 -150 150 152 152 c84 84 155 153 158 153 3 0 72
                -66 153 -148z m842 -302 l-155 -155 -147 148 -148 147 155 155 155 155 147
                -147 148 -148 -155 -155z m3725 -455 l-170 -170 -77 77 -77 77 22 119 c25 137
                86 356 109 391 l15 24 174 -174 174 -174 -170 -170z m1020 170 c0 -6 -76 -86
                -168 -178 l-168 -167 -172 172 -172 173 173 172 172 173 167 -167 c93 -92 168
                -172 168 -178z m688 -172 l-173 -173 -172 172 -173 173 172 172 173 173 172
                -172 173 -173 -172 -172z m854 2 c-92 -93 -168 -171 -170 -174 -3 -6 -342 331
                -342 341 0 4 75 83 168 175 l167 168 172 -170 173 -170 -168 -170z m856 -2
                l-173 -173 -172 172 -173 173 172 172 173 173 172 -172 173 -173 -172 -172z
                m-10118 332 l145 -145 -155 -155 -155 -155 -150 150 -150 150 150 150 c82 82
                154 150 160 150 6 0 75 -65 155 -145z m853 -3 l147 -147 -155 -155 -155 -155
                -150 150 -150 150 152 152 c84 84 155 153 158 153 3 0 72 -66 153 -148z m850
                0 l147 -147 -155 -155 -155 -155 -150 150 -150 150 152 152 c84 84 155 153
                158 153 3 0 72 -66 153 -148z m857 3 l145 -145 -155 -155 -155 -155 -147 147
                -148 148 152 152 c84 84 155 153 158 153 3 0 70 -65 150 -145z m693 -45 c19
                -69 43 -171 53 -228 l18 -103 -39 -39 -39 -40 -148 147 -148 148 127 127 c70
                70 131 124 135 120 4 -4 23 -63 41 -132z m7406 58 c-26 -52 -123 -222 -136
                -237 -2 -3 -19 11 -38 29 l-35 34 117 118 c65 65 119 118 120 118 2 0 -11 -28
                -28 -62z m-11401 -195 l-32 -33 -23 37 c-13 21 -46 83 -74 138 l-51 100 106
                -105 106 -104 -32 -33z m7872 -578 l-170 -170 -172 172 -173 173 170 170 170
                170 172 -172 173 -173 -170 -170z m855 345 l170 -170 -173 -173 -172 -172
                -172 172 -173 173 170 170 c93 93 172 170 175 170 3 0 82 -77 175 -170z m850
                0 l170 -170 -173 -173 -172 -172 -172 173 -173 173 170 169 c94 93 172 169
                175 169 3 1 82 -76 175 -169z m858 -342 c-92 -93 -172 -168 -178 -168 -5 0
                -85 75 -177 167 l-168 168 172 172 173 173 172 -172 173 -173 -167 -167z m719
                475 c21 -20 38 -42 38 -49 0 -15 -191 -271 -251 -337 l-44 -48 -65 65 -65 66
                170 170 c93 94 172 170 174 170 3 0 22 -17 43 -37z m-10765 -360 l-68 -68 -20
                25 c-74 93 -229 304 -229 313 0 6 19 31 42 54 l43 43 150 -150 150 -150 -68
                -67z m773 -88 l-155 -155 -150 150 -150 150 155 155 155 155 150 -150 150
                -150 -155 -155z m850 0 l-155 -155 -150 150 -150 150 155 155 155 155 150
                -150 150 -150 -155 -155z m850 0 l-155 -155 -148 148 -147 147 152 155 153
                155 150 -147 150 -148 -155 -155z m1000 160 c0 -6 -68 -78 -150 -160 l-150
                -150 -147 148 -148 147 155 155 155 155 142 -142 c79 -79 143 -147 143 -153z
                m3330 -10 c0 0 -20 -21 -45 -45 l-45 -44 0 89 0 89 45 -44 c25 -24 45 -45 45
                -45z m258 -602 c-95 -95 -175 -173 -178 -173 -9 1 -78 164 -103 245 -14 44
                -32 125 -42 179 l-16 99 83 84 83 83 172 -172 173 -173 -172 -172z m847 337
                l170 -170 -170 -170 -170 -170 -172 172 -173 173 167 167 c92 93 170 168 173
                168 3 0 82 -76 175 -170z m857 3 l168 -168 -172 -172 -173 -173 -172 172 -173
                173 167 167 c92 93 172 168 178 168 5 0 85 -75 177 -167z m853 -3 l170 -170
                -173 -173 -172 -172 -172 172 -173 173 170 170 c93 93 172 170 175 170 3 0 82
                -77 175 -170z m744 106 l64 -64 -44 -53 c-24 -28 -102 -105 -174 -171 l-129
                -118 -66 65 -65 65 170 170 c93 93 172 170 175 170 3 0 34 -29 69 -64z m-6679
                -126 c-6 -47 -17 -116 -26 -155 -18 -86 -73 -255 -82 -255 -4 0 -68 61 -142
                135 l-135 135 155 155 155 155 42 -43 43 -42 -10 -85z m-3250 -205 c-35 -36
                -69 -65 -75 -65 -14 0 -211 186 -259 245 l-39 47 69 69 69 69 150 -150 150
                -150 -65 -65z m773 217 l147 -147 -155 -155 -155 -155 -147 145 c-81 80 -149
                146 -152 148 -6 3 301 312 309 312 3 0 72 -66 153 -148z m850 0 l147 -147
                -155 -155 -155 -155 -147 147 -148 148 150 153 c83 85 152 155 155 156 3 0 72
                -65 153 -147z m996 -140 c3 -1 -66 -73 -152 -160 l-157 -157 -147 148 -148
                147 155 155 155 155 145 -143 c79 -78 147 -143 149 -145z m4436 -272 l170
                -170 -179 -179 -179 -179 -29 18 c-101 61 -209 162 -294 274 l-32 41 181 183
                c100 100 184 182 187 182 3 0 82 -77 175 -170z m845 -345 l-170 -170 -172 172
                -173 173 170 170 170 170 172 -172 173 -173 -170 -170z m1020 170 c0 -5 -75
                -85 -167 -177 l-168 -168 -172 172 -173 173 172 172 173 173 167 -167 c93 -92
                168 -172 168 -178z m630 224 c0 -10 -179 -145 -294 -223 l-99 -67 -34 33 -33
                32 172 173 173 173 57 -57 c32 -32 58 -60 58 -64z m-6648 -66 l157 -156 -34
                -51 c-68 -102 -228 -266 -259 -266 -6 0 -83 72 -171 160 l-160 160 155 155
                c85 85 155 155 155 155 0 0 71 -70 157 -157z m-2560 0 l148 -147 -37 -38 c-20
                -21 -41 -38 -46 -38 -11 0 -255 174 -307 218 l-35 31 60 60 c33 34 62 61 65
                61 2 0 71 -66 152 -147z m858 2 l145 -145 -155 -155 -155 -155 -150 150 -150
                150 150 150 c83 82 155 149 160 150 6 0 75 -65 155 -145z m850 0 l145 -145
                -155 -155 -155 -155 -147 147 -148 148 152 152 c84 84 155 153 158 153 3 0 70
                -65 150 -145z m4109 -130 c73 -158 205 -342 317 -444 157 -144 301 -229 558
                -330 13 -5 -28 -20 -138 -49 -612 -165 -929 -312 -1524 -710 -62 -41 -114 -73
                -117 -71 -2 3 32 59 76 124 398 596 554 935 714 1550 14 55 28 107 31 115 4 9
                15 -13 29 -52 12 -37 37 -97 54 -133z m-2519 -460 c98 -277 273 -610 513 -972
                51 -79 92 -143 89 -143 -3 0 -13 6 -21 13 -27 23 -278 180 -411 257 -346 201
                -625 314 -1065 430 -88 23 -167 44 -175 47 -8 2 30 21 85 42 158 60 265 120
                400 226 156 122 288 286 382 472 l57 112 52 -182 c29 -100 71 -236 94 -302z
                m3842 178 l168 -168 -66 -66 -65 -66 -102 13 c-158 19 -413 93 -433 126 -7 11
                303 328 321 328 5 0 85 -75 177 -167z m936 -166 c-96 -33 -292 -84 -392 -102
                l-98 -18 -56 57 -57 56 170 170 170 170 157 -157 158 -158 -52 -18z m618 312
                c16 -17 16 -22 4 -30 -17 -11 -150 -82 -165 -88 -5 -2 21 29 60 68 38 39 72
                71 76 71 3 0 14 -9 25 -21z m-8336 -95 l85 -86 -90 48 c-145 79 -134 68 -106
                98 14 14 25 26 25 26 1 0 40 -39 86 -86z m1060 -209 c0 -6 -33 -43 -73 -83
                l-73 -72 -79 16 c-138 27 -329 85 -350 105 -19 19 -18 20 135 174 l155 155
                142 -142 c79 -79 143 -147 143 -153z m714 146 l149 -149 -51 -26 c-99 -50
                -247 -98 -359 -116 l-58 -10 -72 72 -73 73 152 152 c84 84 155 153 158 153 3
                0 72 -67 154 -149z m5296 -283 c0 -4 -19 -8 -42 -8 l-41 0 23 25 c22 23 25 24
                42 8 10 -9 18 -20 18 -25z m-5850 -13 l23 -25 -48 0 -48 0 23 25 c13 14 24 25
                25 25 1 0 12 -11 25 -25z"/>
                <path d="M6363 8548 c-176 -18 -344 -112 -449 -251 -290 -383 -122 -906 341
                -1058 57 -19 92 -24 185 -24 127 0 203 19 310 76 315 168 448 558 296 874
                -127 267 -389 414 -683 383z m105 -105 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4
                30 0 43 -2 28 -4z"/>
            </g>
        </svg>

    )
}


