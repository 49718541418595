import { useCallback, useRef, useState } from 'react'
import { Outlet } from 'react-router'
import { useClickOutside } from '../utils/useClickOutside'
import Footer from './Footer'
import Header from './Header'
import Booking from './Booking'
import Menu from './Menu'



const Page = () => {
    const refMenu = useRef<HTMLDivElement>(null!);
    const [openBook, setOpenBook] = useState<boolean>(false)
    const [openMenu, setOpenMenu] = useState<boolean>(false)


    const closeOpenMenu = useCallback(() => openMenu ? setOpenMenu(false) : null, [openMenu]);
    useClickOutside(refMenu, closeOpenMenu);


    return (
        <div className='w-full h-full'>

            {openMenu &&
            <Menu
                refMenu={refMenu}
                open={openMenu}
                setOpenMenu={setOpenMenu}
                setOpenBook={setOpenBook}
            />
            }

            <Header  openMenu={openMenu} setOpenMenu={setOpenMenu} setOpenBook={setOpenBook} />

            <Outlet />

            <Footer />

            <Booking open={openBook} setOpen={setOpenBook}/>
        </div>
    );
}

export default Page