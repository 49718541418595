import AliceCarousel from 'react-alice-carousel'
import './slide.css';

import { ImageType } from '../types'
import { Image } from './Image'

type SlideshowProps = {
    slides: ImageType[]
    clasName: string
}

const SlideShow = ({slides, clasName}: SlideshowProps) => {

    return (
        
        <AliceCarousel
            items={slides.map((s, k) =>
                <Image
                    key={k}
                    className={clasName}
                    name={s.name}
                    folder={s.folder}
                    title={s.title}
                />
            )}
            autoPlay
            autoPlayStrategy="none"
            autoPlayInterval={1500}
            animationDuration={1500}
            animationType="fadeout"
            infinite
            touchTracking={false}
            disableDotsControls
            disableButtonsControls
        />
    );
}

export { SlideShow }