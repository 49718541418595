import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Page from './views/Page'
import LodgePark from './views/LodgePark'

import { FaqView } from './views/Faq';
import { NoMatchView } from './views/NoMatchView'

const  ClosDuParc = React.lazy(() =>  import('./views/Gites/ClosDuParc'))
const  GueAuLoup = React.lazy(() =>  import('./views/Gites/GueAuLoup'))
const  GitesDuParc = React.lazy(() =>  import('./views/Gites/GitesDuParc'))
const  Chevelliere = React.lazy(() =>  import('./views/Gites/Chevelliere'))
const  Chenilliere = React.lazy(() =>  import('./views/Gites/Chenilliere'))

const Culture = React.lazy(() =>  import("./views/Experiences/Culture"))
const Nature = React.lazy(() =>  import("./views/Experiences/Nature"))
const ArtDeVivre = React.lazy(() =>  import("./views/Experiences/ArtDeVivre"))



const App = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div></div>}>
        <Routes>
          <Route path='/' element={<Page />} >
            <Route index element={<LodgePark />} />
            <Route path='/closduparc' element={<ClosDuParc />} />
            <Route path='/gueauloup' element={<GueAuLoup />} />
            <Route path='/chevelliere' element={<Chevelliere />} />
            <Route path='/gitesduparc' element={<GitesDuParc />} />
            <Route path='/chenilliere' element={<Chenilliere />} />
            <Route path='/culture' element={<Culture />} />
            <Route path='/nature' element={<Nature />} />
            <Route path='/artdevivre' element={<ArtDeVivre />} />
            <Route path='/faq' element={<FaqView />} />
          </Route>

          <Route path="*" element={<NoMatchView />} />

        </Routes>

      </React.Suspense>

    </BrowserRouter>

  )
}

export default App;
