import { ChangeEvent, useEffect, useRef, useState } from "react";
import { BaseInput } from "./BaseInput";

type InputTextareaProps = {
    id?: string
    name: string
    label: string
    text: string
    setText: (t: string) => void
    onChange: (t: ChangeEvent<HTMLTextAreaElement>) => void
    minLength?: number
    maxLength?: number
    containerClass?: string
    isRequire?: boolean
}

const InputTextarea = ({ id, name, label, text, setText, minLength=0, maxLength=500, onChange, containerClass, isRequire = false }: InputTextareaProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const textRef = useRef<HTMLTextAreaElement>(null)

    const [isFocused, setIsFocused] = useState<boolean>(text ? true : false)


    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.height = "auto"
            textRef.current.style.height = textRef.current.scrollHeight + "px"
        }
    },[text])

    return (
        <BaseInput
            id={id}
            label={label}
            labelAnim={!isFocused && text === ''}
            containerRef={containerRef}
            containerClass={[
                containerClass,
                "relative w-full min-h-[40px] flex flex-col rounded-lg border-lodgeGrey border-x border-b mt-3",
                isRequire && text.length >= minLength ? "bg-white bg-opacity-10 " : "bg-danger bg-opacity-20",
                "focus-within:bg-white bg-opacity-10 focus-within:bg-opacity-60",
            ].join(' ')}
            labelClass={[
                isFocused || text !== '' ? '-translate-y-2 scale-75 ml-2' : '-translate-y-0 scale-1 mt-2 pl-3'
            ].join(' ')}
        >
            <textarea
                ref={textRef}
                value={text}
                id={name}
                onChange={event => {
                    event.stopPropagation()
                    setText(event.target.value)
                }}
                minLength={minLength}
                maxLength={maxLength}
                required={isRequire}
                className="w-full shadow-none bg-transparent border-none border-transparent outline-none overflow-hidden resize-none text-lg mt-2 mb-1 mx-3"
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        </BaseInput>
    )
}

export { InputTextarea }