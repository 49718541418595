import { Fragment, useEffect, useRef } from 'react'
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import logoLodgePark from '../assets/Lodge_Park_logo.png'

const MAP_TILE = L.tileLayer(
    `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
);

const lodgeParkIcon = L.icon({
    iconUrl: logoLodgePark,
    iconSize: [26, 26],
    iconAnchor: [12, 24],
    shadowUrl: undefined,
    shadowAnchor: undefined,
    shadowSize: [24, 24],
    popupAnchor: [0, 0]
})

class RecenterControl extends L.Control {
    center: L.LatLng

    constructor(center: L.LatLng) {
        super({position: 'bottomleft'})
        this.center = center
    }

    onAdd(map: L.Map) {
        const container = L.DomUtil.create("div", "leaflet-bar ")
        const link = L.DomUtil.create("a", "h-full w-full ", container)
        const icon = L.DomUtil.create('img', 'bg-lodgeGrey', link)
        icon.setAttribute('src', logoLodgePark)
        link.setAttribute("role", "button")

        const recenter = () => map.panTo(this.center)
        L.DomEvent.on(link, "click", recenter, this)

        return container
    }
}

type MapProps = {
    className: string,
    zoom: number,
    center: {lat: number, lng: number},
    marker1: {coords: {lat: number, lng: number}, popup?: string[]},
    marker2?: {coords: {lat: number, lng: number}, popup?: string[]},
    marker3?: {coords: {lat: number, lng: number}, popup?: string[]},
}

const Map = ({className, zoom, center, marker1, marker2, marker3}: MapProps) => {
    const mapRef = useRef<L.Map | null>(null)

    useEffect(() => {
        const mapParams: L.MapOptions = {
            center: center,
            zoom: zoom,
            zoomControl: true,
            layers: [MAP_TILE],
        }

        mapRef.current = L.map('map', mapParams)

        return () => {
            if (mapRef.current) {
                mapRef.current.off()
                mapRef.current.remove()
            }
        }
    }, [marker1, marker2, marker3, center, zoom])

    useEffect(() => {
        [marker1, marker2, marker3].forEach((m) => {
            if (m && mapRef.current) {
                let popup: string = ''
                if (m.popup) {
                    let content = "<div class='flex flex-col justify-center font-semibold'>"
                    m.popup.forEach((p, k) => {
                        content = content + `<div key=${k}>${p}</div>`
                    })
                    popup = content + '</div>'
                }

                const marker = L.marker(L.latLng(m.coords.lat, m.coords.lng), {icon: lodgeParkIcon})
                if (popup) marker.bindPopup(popup)
                marker.addTo(mapRef.current)
            }
        });
    }, [marker1, marker2, marker3])

    useEffect(() => {
        if (mapRef.current) {
            const recenter = new RecenterControl(center as L.LatLng)
            recenter.addTo(mapRef.current)
        }
    }, [center])

    return (
        <Fragment>
            <div id="map" className={className} />
        </Fragment>
    )
}

export { Map }

