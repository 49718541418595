import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { useDimElement } from "../utils/useDimElement";

type BaseInputProps = {
    id?: string
    children: ReactNode | string;
    label: string
    labelAnim?: boolean
    containerRef: RefObject<HTMLDivElement>
    containerClass: string
    labelClass: string
}

const BaseInput = ({id, children, label, labelAnim = false, containerRef, containerClass, labelClass }: BaseInputProps) => {
    const labelRef = useRef<HTMLDivElement>(null)
    const divDim = useDimElement<HTMLDivElement>(containerRef)
    const [borderTopWidth, setBorderTopWidth] = useState<number>(0)

    useEffect(() => {
        if (labelRef.current) {
            const widthLabel = labelAnim ? 8 : (Math.ceil((labelRef.current.clientWidth * 75) /100) + 8)
            setBorderTopWidth(divDim.width - widthLabel)
        }
    }, [divDim.width, labelAnim])

    return (
        <div
            id={id}
            ref={containerRef}
            className={[
                containerClass,
                'before:absolute before:top-0 before:h-2 before:border-inherit before:left-0 before:w-2 before:border-t before:rounded-tl-lg'
            ].join(' ')}
        >
            <div
                className='absolute top-0 right-0 h-2 border-inherit border-t rounded-tr-lg'
                style={{width: borderTopWidth}}
            />

            <label className='relative w-full h-full'>
                <div
                    ref={labelRef}
                    className={[
                        "w-fit pointer-events-none origin-top-left transition ease-out duration-200",
                        labelClass
                    ].join(' ')}
                >
                    {label}
                </div>
                <div
                    className={[
                        'w-full h-full flex justify-between items-center',
                        labelAnim ? '-mt-8': '-mt-6'
                    ].join(' ')}
                >
                    {children}
                </div>
            </label>
        </div>
    )
}

export { BaseInput }