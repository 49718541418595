import { ImageFolder } from '../types'


type ImageProps = {
    name: string
    folder: ImageFolder
    title?: string
    className?: string
};

const Image = ({name, folder, title, className}: ImageProps) => {

    return (
        <img
            className={className}
            src={require("../assets/" + folder + (folder ? "/" : "") + name)}
            alt={title ? title : name}
        />
    );
}

export { Image }
