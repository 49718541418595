export const Wine = () => {
    return (
        <svg id="svg4170" xmlns="http://www.w3.org/2000/svg" height="282.22mm" width="142.06mm" version="1.1" viewBox="0 0 503.37047 1000" className="w-10 h-10 stroke-white">
            <g id="layer1" transform="translate(1548.8 487.64)">
                <g id="g4144" transform="matrix(8.7568 0 0 8.7568 -5379 -7084.1)">
                    <path id="path3406" d="m466.11 866.99h0.056-21.237c-0.40103-1.5335 8.0645-3.6744 10.304-4.8324 7.7178-3.991 8.6453-6.3559 8.7878-22.408 0.14935-16.821-0.74072-20.59-5.3304-22.57-15.367-6.6283-22.422-18.13-20.479-33.386 0.69321-5.4435 6.9537-27.788 8.3208-30h19.606 19.606c1.3671 2.2121 7.6276 24.557 8.3208 30 1.9428 15.256-5.1122 26.758-20.479 33.386-4.5896 1.9797-5.4797 5.7484-5.3304 22.57 0.14253 16.052 1.07 18.417 8.7878 22.408 2.2393 1.158 10.705 3.2988 10.304 4.8324h-21.237z" stroke="#fff" fill="none"/>
                    <path fill="#fff" id="path4139" d="m50.135 40.15c-1.0053 14.149 6.0859 24.859 20.695 31.16 4.5897 1.98 5.4794 5.7474 5.3301 22.568-0.1425 16.052-1.0693 18.417-8.7871 22.408-2.2395 1.158-10.706 3.2985-10.305 4.832h21.238 21.182c0.401-1.5336-8.0654-3.674-10.305-4.832-7.7178-3.991-8.6446-6.3562-8.7871-22.408-0.1493-16.822 0.74048-20.589 5.3301-22.568 14.614-6.303 21.704-17.012 20.694-31.162h-56.287z" transform="translate(387.86,745.87)"/>
                </g>
            </g>
        </svg>
    )
}

