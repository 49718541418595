import { Country } from "../types";

type ShowFlagProps = {
    country?: Country
    className: string
    classColorNoFlag?: string
}

const ShowFlag = ({country, className, classColorNoFlag}: ShowFlagProps) => {

    return (
        <div className={className} >
            {country && country.code 
                ? <img src={require('../assets/flags/' + country.code + '.svg')} alt={country.title} />
                : <div className={[
                    'h-full w-ful',
                    classColorNoFlag
                ].join(' ')}/>
            }
        </div>
    )
}

export { ShowFlag }