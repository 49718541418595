import { useLocation, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import { ImageType } from "../../types"
import { Image } from "../../components"

//Choisir une image: mane, folder, title (360 x 240)
const experiences: {ns: string, img: ImageType}[] = [
    {ns: 'culture', img: {
        name: 'jardin4.png',
        folder: 'nature',
        title: 'Art de vivre'
    }},
    {ns: 'nature', img: {
        name: 'champ_vignette.png',
        folder: 'nature',
        title: 'tournesol'
    }},
    {ns: 'artdevivre', img: {
        name: 'verres.png',
        folder: 'artdevivre',
        title: 'Art de vivre'
    }}
]

const ExperienceMenu = () => {
    const { t } = useTranslation(['artdevivre','culture','nature', 'nav']);
    const navigate = useNavigate();
    const location = useLocation();

    return (


        <nav className='flex flex-col h-[200px] lg:h-[240px] bg-lodgeGreyLight mx-4 lg:mx-6 mb-8'>

            <div className='font-NotoSans text-center text-lodgeGrey uppercase text-2xl pt-4'>{t('discover', {ns: 'nav'})}</div>

            <div className="w-full h-full flex justify-around items-center">

                {experiences.filter(e => e.ns !== location.pathname.substring(1)).map((e, k) => (
                <button
                    key={k}
                    className='w-full h-fit relative flex items-center justify-center group'
                    onClick={() => {
                        navigate('/' + e.ns)
                        window.scrollTo({top: 0, left: 0, behavior: 'auto'})
                    }}
                >
                    <div className="w-full h-full absolute flex flex-col items-center justify-center bg-transparent text-white hover:text-lodgeRed text-xl lg:text-3xl">
                        {t('title', {ns: e.ns})}
                    </div>

                    <Image
                        name={e.img.name}
                        folder={e.img.folder}
                        title={e.img.title}
                        className="w-[180px] h-[120px] lg:w-[240px] lg:h-[160px] object-cover shadow-lg shadow-gray-600 group-hover:shadow-white"
                    />
                </button>
                ))}

            </div>

        </nav>
    )
}

export default ExperienceMenu