import { useState } from "react"
import { useTranslation } from "react-i18next"
import PhotoAlbum from "react-photo-album"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import Slideshow from "yet-another-react-lightbox/plugins/slideshow"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import "yet-another-react-lightbox/plugins/thumbnails.css"

import { GalleryType } from "../types"
import { ArrowDownCircleIcon } from "@heroicons/react/24/solid"


const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48]

type GalleryProps = {
    images: GalleryType[],
}

const Gallery = ({images}: GalleryProps) => {
    const { t } = useTranslation(['nav'])
    const [index, setIndex] = useState(-1)

    const photos = images.map((i) => {
        const width = breakpoints[0];
        const height = (i.height / i.width) * width;

        return {
            src: require("../assets/" + i.folder + (i.folder ? "/" : "") + i.name),
            width,
            height,
            srcSet: breakpoints.map((breakpoint) => {
                const height = Math.round((i.height / i.width) * breakpoint);
                return {
                    src: require("../assets/" + i.folder + (i.folder ? "/" : "") + i.name),
                    width: breakpoint,
                    height,
                };
            }),
        };
    });

    return (
        <button
            className="w-full group bg-lodgeGreyLight  text-lodgeGrey text-xl hover:text-3xl mb-8 pb-6"
            onClick={() => setIndex(0)}
        >
            <div className="flex justify-center font-NotoSans text-center uppercase  pt-4">
                <div>{t('gallery')}</div>
                <ArrowDownCircleIcon className='w-6 h-6 -rotate-90 group-hover:w-8 group-hover:h-8 ml-2' />
            </div>

            <PhotoAlbum
                photos={photos.slice(0, 5)}
                layout="rows"
                targetRowHeight={150}
            />

            <Lightbox
                slides={photos.map(p => {return {src: p.src}})}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Slideshow, Thumbnails]}
            />
        </button>
    )
}

export { Gallery }
