import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ImageType } from '../types'
import { Image, SlideShow, Presentation } from '../components'
import { Helmet } from 'react-helmet'

//Images du slide de l'en-tête :
//name : nom de l'image
//dossier où se trouve l'image
//title est facultatif, s'il n'est pas précisé c'est le nom de l'image qui pris
//format de l'image idéalement 1200 x 800
const imageSlide: ImageType[] = [
    {name: "chaise-longue.png", folder: "chenilliere", title: ""},
    {name: "chambre-verte3.png", folder: "chenilliere", title: ""},
    {name: "jardin-etang.png", folder: "chenilliere", title: ""},
    {name: "bateaux-loire2.png", folder: "nature", title: ""},
    {name: "facade.png", folder: "closduparc", title: ""},
    {name: "passerelle3.jpg", folder: "gueauloup",  title: ""},
    {name: "suite.png", folder: "gueauloup", title: ""},
    {name: 'champ.png', folder: 'nature', title: 'tournesol'}
]

//Choisir une image pour l'en-tête: mane, folder, title (480 x 320)
const gites: {ns: string, img: ImageType}[] = [
    {ns: 'closduparc', img: {
        name: 'closduparc-facade_vignette.jpg',
        folder: 'closduparc',
        title: 'Le Clos du parc'
    }},
    {ns: 'gueauloup', img: {
        name: 'gueauloup-facade_vignette.png',
        folder: 'gueauloup',
        title: 'Le Gué au Loup'
    }},
    {ns: 'chevelliere', img: {
        name: 'salon-cheminee.jpg',
        folder: 'chevelliere',
        title: 'La Chevellière'
    }},
    {ns: 'gitesduparc', img: {
        name: 'gitesduparc-facades_vignette.png',
        folder: 'gitesduparc',
        title: 'Les Gîtes du Parc'
    }},
    {ns: 'chenilliere', img: {
        name: 'chenilliere-facade_vignette.png',
        folder: 'chenilliere',
        title: 'La Chenillière'
    }}
]

const LodgePark = () => {
    const { t } = useTranslation(['lodgepark', 'closduparc', 'gueauloup', 'gitesduparc', 'chenilliere', 'chevelliere']);
    const navigate = useNavigate();

    return (
        <div className='w-full font-NotoSans'>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Lodge Park Touraine - Gîtes de charmes</title>
                <link rel="canonical" href="https://www.lodge-park.fr" />
            </Helmet>

            <section className='relative top-0 left-0'>

                <div className='w-full h-screen absolute top-0 left-0 justify-center items-center flex flex-col bg-transparent text-white bold z-10 -mt-20'>
                    <a
                        href={'#lgGites'}
                        className='flex flex-col items-center'
                    >
                        <h1 className='font-bold text-3xl mb-1'>{t('title')}</h1>
                        <h2 className='font-semibold text-3xl mb-8 drop-shadow-xl'>{t('subTitle')}</h2>
                    </a>

                    {gites.map((g, k) => (
                        <button
                            key={k}
                            className='flex flex-wrap justify-center font-bold text-2xl mb-4'
                            onClick={() => navigate(g.ns)}
                        >
                            <div className='mr-2' >{t('title', {ns: g.ns})}</div> 
                            <div>({t('body', {ns: g.ns})})</div>
                        </button>
                    ))}
                </div>

                <div className='w-full h-screen top-0 left-0 z-0'>
                    <SlideShow slides={imageSlide}  clasName='w-full h-full object-cover contrast-[.8]'/>
                </div>

            </section>

            <section className="w-full h-fit text-lodgeGrey">

                <Presentation
                    intro={t('presentation', {returnObjects: true})}
                />

            </section>

            <section id='lgGites' className='w-screen h-fit grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 pb-12'>

                {gites.map((g, k) => (
                <button
                    key={k}
                    id={g.ns}
                    className='flex flex-col justify-center items-center scroll-mt-20 snap-start px-10  text-lodgeGrey hover:text-lodgeRed'
                    onClick={() => navigate(g.ns)}
                >
                    <div className="relative max-h-[320px] max-w-full overflow-hidden bg-cover bg-no-repeat mt-10">
                        <Image
                            name={g.img.name}
                            folder={g.img.folder}
                            title={g.img.title}
                            className="max-w-full transition duration-1000 ease-in-out hover:scale-110 hover:translate-y-1"
                        />
                    </div>
                    <h3 className='font-bold text-xl mt-2'>{t('title', {ns: g.ns})}</h3>
                    <div className='whitespace-pre-line'>{t('shortDescription', {ns: g.ns})}</div> 
                </button>
                ))}

            </section>
        </div>
    );
}

export default LodgePark