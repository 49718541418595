import { ChangeEvent, useRef, useState } from "react";
import { BaseInput } from "./BaseInput";

type InputTextProps = {
    id?: string
    name?: string
    label: string
    text: string
    onChange: (t: ChangeEvent<HTMLInputElement>) => void
    containerClass?: string
    isRequire?: boolean
    autofocus?: boolean
}

const InputText = ({id, name, label, text, onChange, containerClass, isRequire= true, autofocus = false }: InputTextProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [isFocused, setIsFocused] = useState<boolean>(text ? true : false)

    return (
        <BaseInput
            id={id}
            label={label}
            labelAnim={!isFocused && text === ''}
            containerRef={containerRef}
            containerClass={[
                containerClass,
                "relative w-full min-h-[2.25rem] flex flex-col rounded-lg border-lodgeGrey border-x border-b mt-3",
                isRequire && text !== '' ? "bg-white bg-opacity-10 " : "bg-danger bg-opacity-20",
                "focus-within:bg-white bg-opacity-10 focus-within:bg-opacity-60",
            ].join(' ')}
            labelClass={[
                isFocused || text !== '' ? '-translate-y-2 scale-75 ml-2' : '-translate-y-0 scale-1 mt-2 pl-3'
            ].join(' ')}
        >
        
            <input
                type='text'
                name={name}
                value={text}
                onChange={onChange}
                className="w-full h-full outline-none shadow-none bg-transparent text-lg mt-2 mb-1 mx-3"
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                required={isRequire}
                autoFocus={autofocus}
                autoComplete={name ? 'true' : 'false'}
            />
        
        </BaseInput>


    );
}

export { InputText }
