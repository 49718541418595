import { ReactNode, RefObject, useEffect, useState } from "react"
import { useDimWindow } from "../utils/useDimWindow"

type BaseSelectProps = {
    children: ReactNode;
    open: boolean
    parentRef: RefObject<HTMLDivElement>
    selectClass: string
}

const BaseSelect = ({children, open, parentRef, selectClass }: BaseSelectProps) => {
    const windowDim = useDimWindow()
    const [selectBottom, setSelectBottom] = useState<boolean>(true)

    //Todo Scroll
    useEffect(() => {
        if (open && parentRef.current && windowDim) {
            const buttonPositionBottom = Math.ceil(parentRef.current.getBoundingClientRect().y)
            windowDim.height - (buttonPositionBottom + 330) > 0 ? setSelectBottom(false) : setSelectBottom(true)
        }
    }, [open, parentRef, parentRef.current?.clientWidth, windowDim])

    return (
        <>
            {parentRef.current &&
            <div
                className={[
                    open ? '' : 'hidden',
                    selectClass,
                    'absolute left-0 w-fit max-h-[200px] z-50',
                    selectBottom ? 'bottom-10' : 'top-10'
                ].join(' ')}
                style={{width: parentRef.current?.clientWidth - 8}}
            >
                {children}
            </div>
            }
        </>
    )
}

export { BaseSelect }