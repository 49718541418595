import { useNavigate } from 'react-router';
import { ChevronLeftIcon, StarIcon} from '@heroicons/react/24/solid';
import Actions from './Actions';

type HeaderProps = {
    openMenu: boolean
    setOpenMenu: (m: boolean) => void
    setOpenBook: (b: boolean) => void
}

const Header = ({openMenu, setOpenMenu, setOpenBook}: HeaderProps) => {
    const navigate = useNavigate()

    return (
        <header >

            <div className='w-full h-20 fixed top-0 left-0 flex justify-between items-center z-20 bg-lodgeGrey opacity-70'>

                <div className='flex flex-row items-center font-Didact text-white font-bold m-4'>
                    {window.location.pathname !== '/' &&
                    <button
                        className='mr-4'
                        onClick={() => navigate(-1)}
                    >
                        <ChevronLeftIcon className='w-8 h-8 border border-white rounded-full' />
                    </button>
                    }

                    <button onClick={() => navigate('/')} className='flex flex-col'>
                        <div className='flex flex-row items-center'>
                            <div className='mr-2'>LODGE PARK</div>
                            {[...Array(4)].map((_,k)  => <StarIcon key={k} className='w-4 h-4 text-yellow-300'/>)}
                        </div>
                        <div>TOURAINE</div>
                    </button>
                </div>

                <Actions
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                    setOpenBook={setOpenBook}
                    mobile={false}
                />

            </div>

            <Actions
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
                setOpenBook={setOpenBook}
                mobile={true}
            />

        </header>
    );
}

export default Header