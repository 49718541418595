import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Coords } from '../types'
import { lodgeParkAddress, lodgeParkLink, giteCoords } from '../parameters'
import { Map } from '../components'
import { Facebook, Instagram, Youtube } from '../assets/links'
import logoLodgePark from '../assets/Lodge_Park_logo.png'

const Footer = () => {
    const { t } = useTranslation(['nav']);
    const location = useLocation();
    const [coords, setCoords] = useState<Coords>(giteCoords.lodgePark)

    useEffect(() => {
        switch(location.pathname) {
            case '/closduparc': setCoords(giteCoords.closDuParc)
                break
            case '/gueauloup': setCoords(giteCoords.gueAuLoup)
                break
            case '/chevelliere': setCoords(giteCoords.chevelliere)
                break
            case '/gitesduparc': setCoords(giteCoords.gitesDuParc)
                break
            case '/chenilliere': setCoords(giteCoords.chenilliere)
                break
            default : if(JSON.stringify(coords) !== JSON.stringify(giteCoords.lodgePark)) setCoords(giteCoords.lodgePark) 
        }
    }, [location.pathname, coords])


    return (
        <footer className="w-full h-fit flex flex-col bg-white text-lodgeGrey border-t border-lodgeGrey pt-2 pb-16 sm:pb-2">

            <div className='w-full h-fit grid grid-cols-1 md:grid-cols-2 justify-center '>

                <div className='flex flex-col items-center p-2 ml-2'>
                    <div className='flex flex-row items-center' >
                        <img src={logoLodgePark} className='w-36 h-36' alt='Logo Lodge Park' />
                        <div className='flex flex-col font-Didact ml-2'>
                            <div>{lodgeParkAddress.title}</div>
                            <div>{lodgeParkAddress.street}</div>
                            <div>{lodgeParkAddress.city + ' - ' + lodgeParkAddress.zipCode}</div>
                            <a href={'tel:' + lodgeParkAddress.phone}>{lodgeParkAddress.phone}</a>
                            <a href={'mailto:' + lodgeParkAddress.mail} >{lodgeParkAddress.mail}</a>
                        </div>
                    </div>

                    <div className='flex flex-row mt-2'>
                        <a className='mr-2' href={"https://facebook.com/" + lodgeParkLink.faceBook} target='_blank' rel="noreferrer" aria-label='Facebook' >
                            <Facebook/>
                        </a>

                        <a className='mr-2' href={"https://instagram.com/" + lodgeParkLink.instagram} target='_blank' rel="noreferrer" aria-label='Instagram'>
                            <Instagram />
                        </a>

                        <a className='' href={"https://youtube.com/channel/" + lodgeParkLink.youtube} target='_blank' rel="noreferrer" aria-label='Youtube'>
                            <Youtube />
                        </a>
                    </div>

                </div>

                <div className='p-2'>

                    <Map
                        className='w-full h-[216px] rounded-lg relative z-10'
                        zoom={coords.zoom}
                        center={coords.center}
                        marker1={coords.marker1}
                        marker2={coords.marker2}
                        marker3={coords.marker3}
                    />

                </div>

            </div>

            <a href="mailto:contact@picomweb.fr?subject=Lodge-Park Contact" className='flex justify-center italic text-sm my-2'>{t('design')} Picomweb</a>

        </footer>
    );
}

export default Footer