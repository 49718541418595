import { ReactNode, useEffect } from 'react';


type ModalBaseProps = {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  containerClass?: string;
  style?: any
}

const ModalBase = ({children, open, onClose, containerClass, style}: ModalBaseProps) => {

  useEffect(() => {
    open ? document.body.style.overflow = "hidden" : document.body.style.overflow = "visible"
  }, [open])

  if (!open) return null;

  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "backdrop") onClose && onClose();
  };
  
  return (
    <div
      id="backdrop"
      onClick={handleOnBackDropClick}
      className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm overflow-auto z-50'
    >
      <div className={["rounded-xl", containerClass].join(' ')} style={style}>
      
        {children}
      
      </div>
      
    </div>
  );
}

export { ModalBase };