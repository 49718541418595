import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { useDimElement } from "../utils/useDimElement";

type BaseContainerLabelProps = {
    id?: string
    name?: string
    children: ReactNode | string;
    label: string
    containerRef: RefObject<HTMLDivElement>
    containerClass: string
    labelClass: string
}

const BaseContainerLabel = ({id, name, children, label, containerRef, containerClass, labelClass }: BaseContainerLabelProps) => {
    const labelRef = useRef<HTMLLabelElement>(null)
    const divDim = useDimElement<HTMLDivElement>(containerRef)
    const [borderTopWidth, setBorderTopWidth] = useState<number>(0)
    const [marginTop, setMarginTop] = useState<number>(0)

    useEffect(() => {
        if (labelRef.current) {
            const widthLabel = (Math.ceil((labelRef.current.clientWidth * 75) /100) - 8)
            setBorderTopWidth(divDim.width - widthLabel)
            setMarginTop(labelRef.current.clientHeight - 24)
        }
    }, [divDim.width])
  
    return (
        <div
            id={id}
            ref={containerRef}
            className={[
                containerClass,
                'before:absolute before:top-0 before:h-2 before:border-inherit before:left-0 before:w-2 before:border-t before:rounded-tl-lg'
            ].join(' ')}
        >
            <div
                className='absolute top-0 right-0 h-2 border-inherit border-t rounded-tr-lg'
                style={{width: borderTopWidth}}
            />
            
            <label
                ref={labelRef}
                htmlFor={name}
                className={[
                    labelClass,
                    'absolute top-0 left-0 origin-top-left pointer-events-none transition ease-out duration-200',
                ].join(' ')}
            >
                {label}
            </label>

            <div
                className='w-full flex justify-between items-center px-2'
                style={{marginTop: marginTop}}
            >
               {children}
            </div>
        
        </div>
    );
}

export { BaseContainerLabel }