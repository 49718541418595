import { GiteCoords, SysCodeLang, ValueLabel } from "./types"

const lodgeParkAddress = {
    title: "Lodge Park Touraine",
    street: "20 Route du plan d'eau",
    city: "Monthou sur Cher",
    zipCode: "41400",
    phone: "+33 6 20 92 25 21",
    mail: "contact@lodge-park.fr"
}

const lodgeParkLink = {
    instagram: "lodgepark",
    faceBook: "LodgeParkMonthou",
    youtube: "UCcKidhVPa2rpw3FEvbF1hDg",
}

const sysDefaultLang: SysCodeLang = 'en'
const sysLangs: {code: SysCodeLang, title: string}[] = [
    {code: 'en', title: 'English'},
    {code: 'fr', title: 'Français'}
]

const lodgeGites: ValueLabel<number>[] = [
    {id: "closduparc", label: "Le Clos du Parc (8 pers.)"},
    {id: "gueauloup", label: "Le Gué au Loup (8 pers.)"},
    {id: "chevelliere", label: "La Chevellière (8 pers.)"},
    {id: "gitesduparc", label: "Les Gîtes du Parc (16 pers.)"},
    {id: "chenilliere", label: "Domaine de la Chenillière (24 pers.)"}
]

const giteCoords: GiteCoords = {
    "lodgePark": {zoom: 10, center: {lat: 47.3509, lng: 1.298}, marker1: {coords: {lat: 47.350612188392766,lng: 1.2988224195242726}, popup:['Le Clos du Parc', "Le Gué au loup", "Les Gîtes du Parc"]}, marker2: {coords: {lat:47.393, lng: 1.205}, popup: ['Domaine de la Chenillière']}, marker3: {coords: {lat:47.314716, lng: 1.359454}, popup: ['La Chevellière']}},
    "gitesDuParc": {zoom: 12, center: {lat: 47.350612188392766, lng: 1.2988224195242726}, marker1: {coords: {lat: 47.350612188392766, lng: 1.2988224195242726}, popup:["Les Gîtes du Parc"]}},
    "closDuParc": {zoom: 12, center: {lat: 47.350612188392766, lng: 1.2988224195242726}, marker1: {coords: {lat: 47.350612188392766, lng: 1.2988224195242726}, popup:["Le Clos du Parc"]}},
    "gueAuLoup": {zoom: 12, center: {lat: 47.350924664816326, lng: 1.2987134338282846}, marker1: {coords: {lat: 47.350924664816326, lng: 1.2987134338282846}, popup:["Le Gué au loup"]}},
    "chevelliere": {zoom: 12, center: {lat: 47.314716, lng: 1.359454}, marker1: {coords: {lat: 47.314716, lng: 1.359454}, popup:["La Chevellière"]}},
    "chenilliere": {zoom: 12, center: {lat:47.393, lng: 1.205}, marker1: {coords: {lat: 47.393, lng: 1.205}, popup:['Domaine de la Chenillière']}},
}

export {
    lodgeParkAddress,
    lodgeParkLink,
    sysDefaultLang,
    sysLangs,
    lodgeGites,
    giteCoords
}