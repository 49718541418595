import { useEffect, useState } from "react"
import { SysCodeLang, CountryCode } from "../types"
import { sysLangs, sysDefaultLang } from "../parameters"
import { useTranslation } from "react-i18next";

export type LocaleSys = {langCode: SysCodeLang, countryCode?: CountryCode | undefined  }

export const useNavGetLocale = (): LocaleSys => {
    const { i18n } = useTranslation();
    const [locale, setLocale] = useState<LocaleSys>({langCode: 'en', countryCode: navigator.language.split('-')[1] as CountryCode});

    useEffect(() => {
        
        const sysLang = sysLangs.find(scl => scl.code === navigator.language.split('-')[0])
        const lang = sysLang ? sysLang.code : sysDefaultLang
        const country = navigator.language.split('-')[1] as CountryCode
        if (lang !== i18n.language) i18n.changeLanguage(lang)

        setLocale({langCode: lang, countryCode: country})
        
    }, [i18n]);

    return locale
}
